// *** Login Mutations*************
export const LOGIN_MUTATION = "loginMutation";

// *** Tours Mutation********
export const GET_TOURS_MUTATION = "toursMutaion";
export const PATCH_TOURS_APPROVED_MUTATION = "tourRequestMutation";
export const PATCH_TOURS_DENIED_MUTATION = "tourRequestDeniedMutation";

// *** Passes Mutation********
export const GET_PASSES_MUTATION = "passesMutaion";

// *** Users Mutation********

export const GET_USER_BY_ID = "usersMutation";

// *** Change Password ********
export const FORGET_PASSWORD_MUTATION = "forgetPasswordMutation";

export const SET_PASSWORD_MUTATION = "setPasswordMutation";

