import React from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import "../css/editTable.css";
import { BsThreeDots } from "react-icons/bs";
import { useEffect } from "react";
import PackageDetail from "../Modals/PackageDetail";
import { useToasts } from "react-toast-notifications";
import Dropdown from "react-bootstrap/Dropdown";
import { useFormik } from "formik";
import delete_confirm from "../../../images/modals/delete.png"

const schema = {
  _id: "",
  number_of_Packages: "",
  article_name: "",
  article_no: "",
  article_content: "",
  package_weight: "",
  package_length: "",
  package_width: "",
  package_height: "",
  package_volume: "",
  delivery_id: "",
  pickup_id: "",
};

const Table = ({ changeAnyThing, setChangeAnyThing, data, isLocal, setData, storage }) => {
  const [rowData, setRowData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [pickupOptions, setPickupOptions] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const [editData, setEditData] = useState();
  const [reloadData, setReloadData] = useState(false);
  const [initialValues, setInitialValues] = useState(schema);
  const [isEdit, setIsEdit] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    const storedData = isLocal ? JSON.parse(localStorage.getItem("pickupDetails")) || [] : data?.pickupDetails;
    const mappedDocuments =
      Array.isArray(storedData) &&
      storedData?.map((document) => ({
        label: document?._id,
        value: document?._id,
      }));

    setPickupOptions(mappedDocuments);
    setChangeAnyThing(true);
  }, [changeAnyThing, setChangeAnyThing, data?.pickupDetails, isLocal, storage]);

  useEffect(() => {
    const storedData = isLocal ? JSON.parse(localStorage.getItem("receiverDetails")) || [] : data?.receiverDetails;
    const mappedDocuments =
      Array.isArray(storedData) &&
      storedData.map((document) => ({
        label: document?._id,
        value: document?._id,
      }));
    setDeliveryOptions(mappedDocuments);
    setChangeAnyThing(true);
  }, [changeAnyThing, setChangeAnyThing, data?.receiverDetails, isLocal, storage]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: false,
    onSubmit: (values) => {
      const documents = isLocal ? JSON.parse(localStorage.getItem("packages")) || [] : data?.packages;
      const documentToUpdate = documents.find(
        (document) => document._id === values?._id
      );
      if (isEdit && documentToUpdate) {
        documentToUpdate.number_of_Packages = values?.number_of_Packages;
        documentToUpdate.article_name = values?.article_name;
        documentToUpdate.article_content = values?.article_content;
        documentToUpdate.article_no = values?.article_no;
        documentToUpdate.package_weight = values?.package_weight;
        documentToUpdate.package_length = values?.package_length;
        documentToUpdate.package_width = values?.package_width;
        documentToUpdate.package_height = values?.package_height;
        documentToUpdate.package_volume = values?.package_volume;
        documentToUpdate.delivery_id = values?.delivery_id;
        documentToUpdate.pickup_id = values?.pickup_id;

        setReloadData(true);
        if(isLocal) {
          localStorage.setItem("packages", JSON.stringify(documents));
        } else if(!isLocal) {
          setData({...data, packages: documents});
        }
        setRowData(documents);
        addToast("Successfully Edited Package Details", {
          appearance: "success",
          autoDismiss: true,
        });
        setIsEdit(false);
        setActiveModal(false);
        setInitialValues(schema);
        formik.resetForm();
      } else {
        documents.push({ ...values, _id: documents?.length + 1 });
        if(isLocal) {
          localStorage.setItem("packages", JSON.stringify(documents));
        } else if(!isLocal) {
          setData({...data, packages: documents});
        }
        addToast("Successfully added Package Details", {
          appearance: "success",
          autoDismiss: true,
        });
        setRowData(documents);
        setActiveModal(false);
        setInitialValues(schema);
        setChangeAnyThing(true);
        formik.resetForm();
      }
      //
    },
  });

  useEffect(() => {     
    const storedData = isLocal ? localStorage.getItem("packages") : data?.packages;
    if (isLocal && Array.isArray(JSON.parse(storedData))) {
      setRowData(JSON.parse(storedData));
    } else if(!isLocal && data) {
      setRowData(storedData);
    }
    setChangeAnyThing(false);
  }, [data, isLocal, storage, changeAnyThing]);

  const handleAddRow = () => {
    setActiveModal(true);
  };

  const handleDelete = (row) => {
    setDeleteModal(true);
    setDeleteId(row?._id);
    setChangeAnyThing(true);
  };

  const handleEditRow = (row) => {
    setInitialValues(row);
    setActiveModal(true);
    setEditData(row);
    setIsEdit(true);
    setChangeAnyThing(true);
  };

  const deletePickupDetail = () => {
    const documents = isLocal ? JSON.parse(localStorage.getItem("packages")) : data?.packages;
    const filteredDocuments = documents.filter(
      (document) => document?._id !== deleteId
    );
    if(isLocal) {
      localStorage.setItem("packages", JSON.stringify(filteredDocuments));
    } else if(!isLocal) {
      setData({...data, packages: filteredDocuments})
    }
    setRowData(filteredDocuments);
    setChangeAnyThing(true);
    setDeleteModal(false);
    addToast("Successfully deleted", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const [hoveredRowId, setHoveredRowId] = useState(null);

  const columns = [
    {
      name: "Sr #",
      width: "150px",
      selector: (row) => { 
        return (
          <div>
            <div className="coloumn_text">{row?._id}</div>
            {hoveredRowId === row._id && (
              <div className="actions__below">
                <span
                  onClick={() => handleEditRow(row)}
                >
                  Edit
                </span>
                <span
                 onClick={() => handleDelete(row)}
                  className="delete_action"
                >
                  Delete
                </span>
              </div>
            )}
          </div>
        )}
    },
    {
      name: "No of Packages",
      width: "140px",
      selector: (row) => (
        <div className="coloumn_text">{row.number_of_Packages}</div>
      ),
    },
    {
      name: "Article Name",
      selector: (row) => {
        return (
          <div className="coloumn_text">
            {row.article_name}
          </div>
        )
      }
    },
    {
      name: "Article #",
      selector: (row) => <div className="coloumn_text">{row.article_no}</div>,
    },
    {
      name: "Package Content",
      width: "140px",
      selector: (row) => <div className="coloumn_text">{row.article_content}</div>,
    },
    {
      name: "Package Weight (kg)",
      width: "160px",
      selector: (row) => <div className="coloumn_text">{row.package_weight}</div>,
    },
    {
      name: "Package Length (cm)",
      width: "160px",
      selector: (row) => <div className="coloumn_text">{row.package_length}</div>,
    },
    {
      name: "Package Width (cm)",
      width: "160px",
      selector: (row) => <div className="coloumn_text">{row.package_width}</div>,
    },
    {
      name: "Package Height (cm)",
      width: "160px",
      selector: (row) => <div className="coloumn_text">{row.package_height}</div>,
    },
    {
      name: "Package Volume (Cubie Meters)",
      width: "230px",
      selector: (row) => <div className="coloumn_text">{row.package_volume}</div>,
    },
    {
      name: "Pick-up Id",
      selector: (row) => (
        <div >
          {row.pickup_id}
        </div>
      ),
    },
    {
      name: "Delivery Id",
      selector: (row) => (
        <div >
          {row.delivery_id}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="flex">
            <Dropdown drop="up">
              <Dropdown.Toggle
                className="mx-1 header-dropdown btn-primary custom-toggle"
                variant="secondary"
                id="dropdown-basic"
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  margin: "0",
                  padding: "0",
                }}
              >
                <BsThreeDots />
              </Dropdown.Toggle>
              <Dropdown.Menu menuPortalTarget={document.body}>
                <Dropdown.Item onClick={() => handleEditRow(row)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(row)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    table: {
      style: {
        height: `${rowData?.length > 2 ? "220px" : "180px"}`
      }
    },
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };
  
  return (
    <>
      <div className={`${rowData?.length > 2 ? "" : ""} border-table`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="package-details-heading">Package Details</div>

          <button
            onClick={handleAddRow}
            className=" margin-r add-detail-button"
          >
            <span className="text-btn">Add Details</span>
          </button>
        </div>
        <div>
          <DataTable
            className={`${rowData?.length > 2 ? "" : ""}`}
            columns={columns}
            data={rowData}
            customStyles={customStyles}
            onRowMouseEnter={(row) => setHoveredRowId(row._id)}
            onRowMouseLeave={(row) => setHoveredRowId(null)}
          />
        </div>
      </div>
      <PackageDetail
        formik={formik}
        data={editData}
        showModal={activeModal}
        setShowModal={setActiveModal}
        pickupOptions={pickupOptions}
        deliveryOptions={deliveryOptions}
        setReloadData={setReloadData}
        setRowData={setRowData}
        setInitialValues={setInitialValues}
        schema={schema}
        updateData={data}
        isLocal={data ? false : true}
        setData={setData}
      />
      {deleteModal && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img src={delete_confirm} alt="" style={{ width: "200px", height: "183px" }} />
              <p>Are you sure?</p>
              <p>
                You will lose all your contractor details.
              </p>
              <div className="edit__btns">
                <button className="edit_no" onClick={() => setDeleteModal(false)}>
                  No
                </button>
                <button
                  className={`edit_yes`}
                  onClick={deletePickupDetail}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
