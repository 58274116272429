import { Navigate, Outlet, useLocation } from "react-router-dom";
import Sidebar from "../components/verticalLayout";
import Header from "../components/header";
import "./css/auth.css";
import React, { useState } from "react";
import { TabContext } from "../context/TabContext";

export const getToken = () => JSON.parse(localStorage.getItem("token"));


const useAuth = () => {
  const token = localStorage.getItem("token");

  if (token) return true;
  else return false;

};


const PrivateRoute = ({ allowedRoles }) => {
  const [navShow, setNavShow] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const isAuth = useAuth();

  const location = useLocation();
  const currentPath = location.pathname;
  const [ active,setActive ] = useState(false);
  const containerFluid = (inactive) => setActive(inactive);

  const [ inactive,setInactive ] = useState(true);
  const [ expand,setExpand ] = useState(false)

  const expandFunc = () => {
      if (inactive === true) return;
      setExpand(!expand);
  }

  return isAuth ? (
    <TabContext.Provider value={{ activeTabJustify, setActiveTabJustify }}>
      <div className="">
        <div>
          {
              !(currentPath === "/create_contractor" ||
                currentPath === "/create_customer" ||
                currentPath === "/create_driver") && (
                <Sidebar 
                  onCollapse={containerFluid} 
                  inactive={inactive}
                  expand={expand}
                  expandFunc={expandFunc}
                />
              )
          }
          <div>
            {
              !(currentPath === "/create_contractor" ||
              currentPath === "/create_customer" ||
              currentPath === "/create_driver") && (
              <div>
                <Header
                  navshow={navShow}
                  setNavShow={setNavShow}
                  className="header-row"
                  fixed="top"
                  onCollapse={containerFluid}
                  inactive={inactive}
                  expand={expand}
                  expandFunc={expandFunc}
                  setInactive={setInactive}
                  setExpand={setExpand}
                />
              </div>
            )
            }
            <div className={`outletWrap ${active ? 'active' : ''}`}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </TabContext.Provider>
  ) : (
    <div>
      <Navigate replace to="/login" />
    </div>
  );
};

export default PrivateRoute;
