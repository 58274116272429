const host = window.location.host;

//Set Actions

export const setVerifyToken = (tokenData) => {
  localStorage.setItem(`${host}_verify_token`, JSON.stringify(tokenData));
};

export const menuActive = (menu) => {
  localStorage.setItem(`menu_active`, JSON.stringify(menu));
};

//Get Actions

export const getVerifyToken = () => {
  return localStorage.getItem(`token`);
};

export const getNameToken = () => {
  return localStorage.getItem(`name`);
};

export const getRoleToken = () => {
  return localStorage.getItem(`role`);
};

export const getMenuActive = () => {
  return localStorage.getItem(`menu_active`);
};

// Remove Actions

export const removeVerifyToken = () => {
  localStorage.removeItem(`${host}_verify_token`);
};
