import React from 'react'
import './css/profile.css'
import { useState } from 'react'
import ProfileTab from './Tabs/ProfileTab';
import SecurityTab from './Tabs/SecurityTab';
import DataExportTab from './Tabs/DataExportTab';
import DeleteAccountTab from './Tabs/DeleteAccountTab';
import Menu from './Menu';
import Map from './maps';
import { BiUserCircle } from 'react-icons/bi';

const TABS = {
  "My Profile": ProfileTab,
  "Security": SecurityTab,
  "Data Export": DataExportTab,
  "Delete Account": DeleteAccountTab
}

const Profile = () => {
  const [activeTab, setActiveTab] = useState("My Profile");
  const [userAddress, setUserAddress] = useState({ address: "" });
  const [addressMapToggle, setAddressMapToggle] = useState(false);

  const Tab = TABS[activeTab];

  return (
    <>
      <div className="breadcrumb">
        <BiUserCircle className="breadcrumb_icon" />
        <span>/</span>
        <p>Profile</p>
      </div>
      <div className={addressMapToggle ? "deactivate profile__wrapper" : "profile__wrapper"}>
        <Menu activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className='profile__data'>
          <div className="title">
            <p>{activeTab}</p>
          </div>
          <Tab setAddressMapToggle={setAddressMapToggle} userAddress={userAddress}/>
        </div>
      </div>
      {
        addressMapToggle && (
          <div style={{ marginTop: "20px" }}>
            <div style={{ border: "5px solid white", margin: "0px 15px" }}>
              <Map
                visible={addressMapToggle}
                setVisible={setAddressMapToggle}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                name={"userProfile"}
              />
            </div>
          </div>
        )
      }
    </>
  )
}

export default Profile