import { useFormik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { personalInfoSchema, validationSchema } from '../Schema/personalnfo';
import { useToasts } from 'react-toast-notifications';

const SecondSection = ({profile, refetch, profileLoader, setProfileLoader}) => {

  const [editSecondSection, setEditSecondSection] = useState(false);
  const [personalInfo, setPersonalInfo] = useState(personalInfoSchema)

  const token = localStorage.getItem("token");
  const { addToast } = useToasts();

  const editProfileFunc = async (data) => {
    setProfileLoader(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/users/myProfile/`,
      {
        method: "PATCH",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        setProfileLoader(false);
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      addToast(resJson.error, { appearance: "error" , autoDismiss: true,});
      setEditSecondSection(false);
      setProfileLoader(false);
      throw new Error(resJson.error.message);
    }
  
    await refetch();
    setEditSecondSection(false);
    setProfileLoader(false);
    addToast("Updated Successfully", { appearance: "success" , autoDismiss: true,});
  };

  const formik = useFormik({
    initialValues: personalInfo,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,

    onSubmit: (values) => {
      editProfileFunc(values);
    },
  });

  return (
    <div className="profile__first__section tab__section" style={{alignItems: editSecondSection ? "flex-end" : "flex-start"}}>
        <div className='profile__second__section__left' style={{gap: editSecondSection ? "30px" : "15px", width: editSecondSection ? "100%" : "400px"}}>
          <p className='info'>Personal Information</p>
          {
            editSecondSection ? (
              <div className='personal__second__edit'>
                <div className="personal-grid2">
                  <div>
                    <label htmlFor="" className="grid-label">
                      First Name
                    </label>
                    <div className="input__with__error">
                      <div className="assign_rate_with_logo">
                        <input
                          placeholder="Enter First Name"
                          name="address"
                          onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
                          value={formik.values?.firstName}
                          defaultValue={formik.values?.firstName}
                          type="text"
                        />
                      </div>
                      <span>{formik?.errors?.firstName}</span>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="grid-label">
                      Last Name
                    </label>
                    <div className="input__with__error">
                      <div className="assign_rate_with_logo">
                        <input
                          placeholder="Enter Last Name"
                          name="postalCode"
                          onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
                          value={formik.values?.lastName}
                          defaultValue={formik.values?.lastName}
                          type="text"
                        />
                      </div>
                      <span>{formik.errors.lastName}</span>
                    </div>
                  </div>  
                </div>

                <div className="personal-grid2">
                  <div>
                    <label htmlFor="" className="grid-label">
                      Email
                    </label>
                    <div className="input__with__error">
                      <div className="assign_rate_with_logo">
                        <input
                          placeholder="Enter Email"
                          name="address"
                          onChange={(e) => formik.setFieldValue('email', e.target.value)}
                          value={formik.values?.email}
                          defaultValue={formik.values?.email}
                          type="text"
                        />
                      </div>
                      <span>{formik?.errors?.email}</span>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="grid-label">
                      Phone
                    </label>
                    <div className="input__with__error">
                      <div className="assign_rate_with_logo">
                        <input
                          placeholder="Enter Phone"
                          name="postalCode"
                          onChange={(e) => formik.setFieldValue('mobileNumber', e.target.value)}
                          value={formik.values?.mobileNumber}
                          defaultValue={formik.values?.mobileNumber}
                          type="text"
                        />
                      </div>
                      <span>{formik.errors.mobileNumber}</span>
                    </div>
                  </div>  
                </div>

                {/* <div className="personal-grid2">
                  <div>
                    <label htmlFor="" className="grid-label">
                      Bio
                    </label>
                    <div className="input__with__error">
                      <div className="assign_rate_with_logo">
                        <input
                          placeholder="Enter Bio"
                          name="invoiceAddress"
                          onChange={(e) => formik.setFieldValue('bio', e.target.value)}
                          value={formik.values?.bio}
                          defaultValue={formik.values?.bio}
                          type="text"
                        />
                      </div>
                      <span>{formik.errors.bio}</span>
                    </div>
                  </div>
                </div> */}
              </div>
            ) : (
              <div className='personal__second__left'>
                <div className='personal__second__col_main'>
                  <div className='personal__second__col'>
                    <p>First Name</p>
                    <h3>{profile?.firstName}</h3>
                  </div>
                  <div className='personal__second__col'>
                    <p>Email Address</p>
                    <h3>{profile?.email}</h3>
                  </div>
                </div>
                <div className='personal__second__col_main'>
                  <div className='personal__second__col'>
                    <p>Last Name</p>
                    <h3>{profile?.lastName}</h3>
                  </div>
                  <div className='personal__second__col'>
                    <p>Phone</p>
                    <h3>{profile?.mobileNumber}</h3>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className='profile__first__section__right'>
          {
            editSecondSection ? (
              <div className='profile__first__section__right'>
                <span className='edit__btn' onClick={() => formik.handleSubmit()}>Save</span>
                <span className='edit__btn' onClick={() => setEditSecondSection(!editSecondSection)}>Cancel</span>
              </div>
            ) : (
              <div 
                className='edit__btn' 
                onClick={() => {
                  formik.setFieldValue('firstName', profile?.firstName);
                  formik.setFieldValue('lastName', profile?.lastName)
                  formik.setFieldValue('email', profile?.email)
                  formik.setFieldValue('mobileNumber', profile?.mobileNumber)
                  setEditSecondSection(!editSecondSection);
                }}
              >
                <span>Edit</span>
                <AiOutlineEdit size={18} />
              </div>
            )
          }
        </div>
    </div>
  )
}

export default SecondSection