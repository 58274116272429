import { Route, Routes, HashRouter, Navigate } from "react-router-dom";
import Login from "./login";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import PrivateRoute from "../services/auth";
import Pages from "../routes/index.js";
import { useJsApiLoader } from "@react-google-maps/api";


function App() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    libraries: ["places"],
  });
  return (
    <>
    {
      isLoaded && (
        <HashRouter>
          <Routes>
            <Route path='/' element={<Navigate replace to='/login' />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forget-password' element={<ForgetPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='' element={<PrivateRoute />} key={"1xbt"}>
              {Pages.map((page) => (
                <Route path={page?.path} element={page?.element} key={page?.id} />
              ))}
            </Route>
          </Routes>
        </HashRouter>
      )
    }
    </>
  );
}

export default App;
