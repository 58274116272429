import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import "./css/loginmodel.css";
import { Link } from "react-router-dom";
import CloseButton from "react-bootstrap/CloseButton";

const LoginModel = (props) => {
  return (
    <>
      <Modal isOpen={props.show} centered={true}>
        <div className='lgModelBody'>
          <Row>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row className='mb-3'>
                  <Col md={10} xl={10} lg={10} sm={10} xs={10}>
                    <span>Forgot Password ?</span>
                  </Col>
                  <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                    <span className='lgModelCancel'>
                      <GrFormClose
                        size={25}
                        onClick={() => {
                          props.setModelShow(false);
                        }}
                      />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <p>
                      No Worries, you will get a link to reset password on your
                      email address
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <p style={{ fontWeight: 600 }}>Enter your email</p>
                  </Col>
                </Row>
                <Row className=''>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Input
                      id='example-date-input'
                      placeholder='Email Address'
                      className='lgInput'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Link to='/'>
                      <Button className='waves-effect waves-light mr-1 w-100 lgbtn '>
                        Send Email
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default LoginModel;
