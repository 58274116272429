// ****** orders get Api******
export const getOrdersFunc = async (token, status, date) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/all-shippments`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      //   history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};

// ****** order get Api******
export const getOrderFunc = async (token, id) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/all-packgaes/${id}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      //   history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};

// ****** orders get Api******
export const getRecentorders = async (token) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/orders/recentOrders`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      //   history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};



// ****** invocies get Api******
export const getInvoicesFunc = async (token, status, date) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/all-invoices`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      //   history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};
