import { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../Loader";
import './css/table.css';

const Table = (prop) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: "65px", // override the row height
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        background: "#06A57A",
        height: "29px",
        color: "#FFFFFF",

        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "106.68%",
      },
    },
    subHeader: {
      style: {
        background: "#f1f6ff",
        height: "auto",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
        borderRadius: "29px 29px 0px 0px",
      },
    },
    subHeaderComponent: {
      background: "#f1f6ff",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  //theme specific
  createTheme(
    "solarized",
    {
      text: {
        primary: "#443E3E",
        secondary: "#443E3E",
      },
      background: {
        default: "#FFFFFF",
      },
    },
    "light"
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  return (
    <DataTable
      title={prop.title}
      columns={prop.columns}
      data={prop.data}
      pagination={prop.pagination}
      fixedHeader={prop.fixedHeader}
      fixedHeaderScrollHeight={prop.fixedHeaderScrollHeight}
      selectableRows={prop.selectableRows}
      responsive={true}
      selectableRowsHighlight={prop.selectableRowsHighlight}
      highlightOnHover={prop.highlightOnHover}
      subHeader={prop.subHeader}
      subHeaderComponent={prop.subHeaderComponent}
      selectableRowsComponent={prop.selectableRowsComponent}
      selectableRowsComponentProps={prop?.selectableRowsComponentProps}
      selectableRowSelected={prop?.selectableRowSelected}
      onRowSelected={prop.onRowSelected}
      subHeaderAlign={prop.subHeaderAlign}
      customStyles={prop.customStyles ? prop.customStyles : customStyles}
      theme="solarized"
      subHeaderWrap={prop.subHeaderWrap}
      onSelectedRowsChange={prop.onSelectedRowsChange}
      clearSelectedRows={prop.clearSelectedRows}
      selectableRowsSingle={prop.selectableRowsSingle}
      progressPending={prop.progressPending}
      progressComponent={<Loader />}
      onRowClicked={prop.rowClicking}
      onRowMouseEnter={(row) => prop.onRowHover(row._id)}
      onRowMouseLeave={() => prop.onRowHover(null)}
      paginationDefaultPage={currentPage}
      paginationPerPage={rowsPerPage}
      onChangePage={handlePageChange}
      paginationComponent={customPagination}
      paginationComponentOptions={{
        custom: true,
        paginationTotalRows: prop?.data?.length,
        paginationPerPage: rowsPerPage,
        paginationRowsPerPageOptions: [10, 20, 50],
        paginationComponent: customPagination,
        currentPage: currentPage,
        rowsPerPage: rowsPerPage,
        setPage: handlePageChange,
        prop: prop
      }}
    // maxHeight='520px' maxWidth='520px'
    />
  );
};

const customPagination = ({ paginationComponentOptions }) => {
  const { setPage, currentPage, rowsPerPage, prop } = paginationComponentOptions;

  const handlePageChange = (page) => {
    setPage(page);
  };

  const totalRows = prop?.data?.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const displayPageNumbers = [];

  const displayMaxPageNumbers = 3;
  const displayEllipsis = "...";

  let startRange = Math.max(currentPage - Math.floor(displayMaxPageNumbers / 2), 1);
  let endRange = Math.min(startRange + displayMaxPageNumbers - 1, totalPages);

  if (totalPages - endRange < Math.floor(displayMaxPageNumbers / 2)) {
    startRange = Math.max(totalPages - displayMaxPageNumbers + 1, 1);
  }

  for (let i = startRange; i <= endRange; i++) {
    displayPageNumbers.push(i);
  }

  const startCustomer = (currentPage - 1) * rowsPerPage + 1;
  const endCustomer = Math.min(startCustomer + rowsPerPage - 1, totalRows);

  return (
    <div>
      {prop?.data?.length > rowsPerPage && (
        <div className="pagination__wrapper">
          <div className="showing-customers">
            Showing {startCustomer} to {endCustomer} of {totalRows} results
          </div>
          <div className="pagination_numbers">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="arrow"
              style={{ marginRight: "5px" }}
            >
              &lt;
            </button>

            {startRange > 1 && (
              <>
                <button className="page" onClick={() => handlePageChange(1)}>1</button>
                {startRange > 2 && <span className="pagination-ellipsis">{displayEllipsis}</span>}
              </>
            )}

            {displayPageNumbers.map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                className={currentPage === pageNumber ? "active-page" : "page"}
              >
                {pageNumber}
              </button>
            ))}

            {endRange < totalPages && (
              <>
                {endRange < totalPages - 1 && <span className="pagination-ellipsis">{displayEllipsis}</span>}
                <button
                  onClick={() => handlePageChange(totalPages)}
                  className={currentPage === totalPages ? "active-page" : "page"}
                >
                  {totalPages}
                </button>
              </>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="arrow"
              style={{ marginLeft: "5px" }}
            >
              &gt;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};



export default Table;
