import React from "react";
import { useState } from "react";
import {AiOutlineLoading} from 'react-icons/ai';
import user from '../../../images/user-placeholder.png'

const DriverSearchProfile = ({
  searchDriverProfile,
  imageModal,
  contractor,
  setSearchDriverProfile,
  assignContractor,
}) => {
  const [loading, setLoading] = useState();
  return (
    <div>
      {searchDriverProfile && (
        <div className="modalMask">
          <div
            style={{ width: "521px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__header2">
              <div className="profile-picture">
                {
                  contractor?.profilePhoto ? (
                    <img className="profile-img" src={contractor?.profilePhoto} alt="useravatar" />
                  ) : (
                    <img className="profile-img" src={user} alt="useravatar" />
                  )
                }
              </div>
            </div>
            <div className="modal__body">
              <div className="contractor_body2">
                <div className="contractor_name">
                  {`${contractor?.firstName} ${contractor?.lastName}`.replace(
                    /\b\w/g,
                    (match) => match.toUpperCase()
                  )}
                </div>
                <div className="contractor_info">
                  <div>
                    <div>Phone :</div>
                    <div>Email :</div>
                    <div>vehicle Type:</div>
                    <div>Address :</div>
                  </div>
                  <div>
                    <div>{contractor?.mobileNumber}</div>
                    <div>{contractor?.email}</div>
                    <div>Volvo FH-16</div>
                    <div>{contractor?.address}</div>
                  </div>
                </div>
                <div className="contractor_btn2">
                  <div>
                    <button className="modal__btn3">
                      <div onClick={() => setSearchDriverProfile(false)}>
                        Not Now
                      </div>
                    </button>
                  </div>
                  <div>
                    <button
                      className="modal__btn4"
                      onClick={() => {
                        setLoading(true);
                        setTimeout(() => {
                          assignContractor();
                          setLoading(false);
                        }, 1000);
                      }}
                    >
                      {loading ? <AiOutlineLoading className="loaderIcon" size={18} />  : "Assign"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DriverSearchProfile;
