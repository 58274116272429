import React, { useEffect, useState } from "react";
import dollar_img from "../../../images/shipment/dollar.svg";
import pounds_img from "../../../images/shipment/euro.svg";
import "../css/modal.css";
import { BsCurrencyEuro } from "react-icons/bs";
import modalImage1 from "../../../images/modals/expense-demo.png";
import Dropdown from "../../../components/Select";


const ExpenseModal = ({
  setExpenseModal,
  selectedCurrency,
  total,
  setTotal,
  formik,
  isLocal
}) => {
  const [expenses, setExpenses] = useState([{ type: "", value: 0 }]);
  const [typesNum, setTypesNum] = useState(1);

  function onAddMore() {
    setExpenses([...expenses, { type: "", value: 0 }]);
    setTypesNum((prev) => prev + 1);
  }

  useEffect(() => {
    const storedExpenses = localStorage.getItem("expenses");
    const parsedExpenses = JSON.parse(storedExpenses);
    if (storedExpenses && isLocal) {
      setExpenses(parsedExpenses);
    }
    if(isLocal) {
      setTypesNum(parsedExpenses?.length);
    }
  }, [isLocal]);

  function onValueChange(e, index) {
    const newArray = [...expenses];
    newArray[index].value = e.target.value;
    setExpenses(newArray);
  }

  const getTotal = () => {
    return expenses.reduce((accumulator, currentValue) => {
      return +accumulator + +currentValue.value;
    }, 0);
  };

  const onSave = () => {
    setTotal(() => getTotal());
    formik.setFieldValue("expense", getTotal());
    let ex = [...expenses];
    const filteredData =
      ex.length > 1
        ? ex.filter(
          (item) =>
            item.type !== "" && (item.number !== "" || item.number !== 0)
        )
        : ex;
    localStorage.setItem("expenses", JSON.stringify(filteredData));
    setExpenseModal(false);
  };

  const onCancel = () => {
    setExpenseModal(false);
  };

  const defaultCurrency = { label: "pounds", value: "pounds" };
  const [currency, setCurrency] = useState(selectedCurrency);

  const CurrencyOptions = [
    { label: "dollar", value: "dollar" },
    { label: "pounds", value: "pounds" },
    { label: "euro", value: "euro" },
  ];


  const currencyHandler = (selectedOption) => {
    setCurrency(selectedOption);
    formik.setFieldValue('expenseCurrency', selectedOption.value)
  };

  const IndicatorSeparator = () => null;

  return (
    <div className="modalMask">
      <div
        style={{ borderRadius: "13px" }}
        className="userModalWrapper expense__modal"
      >
        <form action="" onSubmit={onSave}>
          <div
            style={{ padding: "36px" }}
            className="usermodal__body modal__body expense__modal__body"
          >
            <div className="flex justify-flex">
              <img src={modalImage1} alt="" />
            </div>
            <div className="customer-details">Choose Expense Category</div>
            <div className="please-enter-the-req">
              Please enter the required details correctly. Thanks!
            </div>
            <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
              <p className="select__currency">Select Currency</p>
              <Dropdown
                options={CurrencyOptions}
                value={formik.values.expenseCurrency !== "" ? {label: formik.values.expenseCurrency, value: formik.values.expenseCurrency} : selectedCurrency}
                onChange={currencyHandler}
                isClearable={false}
                components={{ IndicatorSeparator }}
                error={false}
              />
            </div>
            <div className="input__boxes">
              {expenses.map((item, index) => (
                <div className="flex" key={index}>
                  <div style={{ width: "50%" }}>
                    <label htmlFor="" className="grid-label">
                      Type
                    </label>
                    <div className="input__with__error">
                      <div className="assign_rate_with_logo">
                        <input
                          type="text"
                          placeholder="Enter Type"
                          value={item.type}
                          onChange={(e) => {
                            const newArray = [...expenses];
                            newArray[index].type = e.target.value;
                            return setExpenses(newArray);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <label htmlFor="" className="grid-label">
                      Amount
                    </label>
                    <div className="input__with__error">
                      <div className="assign_rate_with_logo">
                        <input
                          type="text"
                          value={item.value}
                          onChange={(e) => onValueChange(e, index)}
                        />
                        {formik?.values?.expenseCurrency === "pounds" ? (
                          <img src={pounds_img} alt="pounds" />
                        ) : formik?.values?.expenseCurrency === "euro" ? (
                          <BsCurrencyEuro />
                        ) : (
                          <img src={dollar_img} alt="dollar" />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="assign_rate_with_logo">
                  <input
                    type="text"
                    value={item.value}
                    onChange={(e) => onValueChange(e, index)}
                  />
                  {selectedCurrency && selectedCurrency.value === "pounds" ? (
                    <img src={pounds_img} alt="pounds" />
                  ) : selectedCurrency && selectedCurrency.value === "euro" ? (
                    <BsCurrencyEuro />
                  ) : (
                    <img src={dollar_img} alt="dollar" />
                  )}
                </div> */}
                  {index + 1 === typesNum && (
                    <button
                      className="create_user_btn"
                      onClick={onAddMore}
                    // disabled={!expenses[index+1].type || !expenses[index+1].value}
                    >
                      Add More
                    </button>
                  )}
                </div>
              ))}
            </div>
            <div className="flex">
              <div className="width-cal">
                <label htmlFor="" className="grid-label">
                  Total Amount:{" "}
                </label>
                <div className="assign_rate_with_logo">
                  <input type="text" value={getTotal()} />
                  {formik?.values?.expenseCurrency === "pounds" ? (
                    <img src={pounds_img} alt="pounds" />
                  ) : formik?.values?.expenseCurrency === "euro" ? (
                    <BsCurrencyEuro />
                  ) : (
                    <img src={dollar_img} alt="dollar" />
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-center gap-4">
              <div className="flex__between mt-4">
                <button type="button" className="button-secondary" onClick={onCancel}>
                  Cancel
                </button>
                <button type="submit" className="button-primary" id="saveButton">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div >
  );
};

export default ExpenseModal;
