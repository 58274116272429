import React from "react";
import "../css/shipment.css";
import pin from "../../../images/icons/pin.svg";
import { useState } from "react";
import { useEffect } from "react";
import { receiverSchema } from "../Validations";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import TimeKeeper from "react-timekeeper";
import {RiSearchLine} from 'react-icons/ri';  
import {BsPinAngle} from 'react-icons/bs';
import SearchCustomerModal from "../Modals/SearchCustomerModal";
import { Autocomplete } from "@react-google-maps/api";


function generateUniqueID() {
  let currentDate = new Date();
  let milliseconds = currentDate.getTime();
  let uniqueID = milliseconds % 1000000;

  return uniqueID.toString().padStart(6, "0");
}

const receiverDetailsSchema = {
  shipmentId: "",
  name: "",
  phone: "",
  email: "",
  address: "",
  city: "",
  state: "",
  date: "",
  time: "",
  postcode: "",
  country: "",
  lat: "",
  lng: "",
  company: "",
  specialInstructions: "",
};

const ReceiverDetails = ({
  addressMapToggleReceiver,
  setAddressMapToggleReceiver,
  receiverAddress,
  setChangeAnyThing,
  changeAnyThing,
  setReceiverAddress,
  setFocusSave,
  focusSave,
  dataLength,
  data,
  isLocal,
  setData
}) => {
  const [receiverDetails] = useState(receiverDetailsSchema);
  const [flag, setFlag] = useState(false);
  const [startTime, setStartTime] = React.useState("");
  const [showTime, setShowTime] = useState(false);
  const [time, setTime] = useState("12:34pm");
  const [searchCustomer, setSearchCustomer] = useState(false);
  const [placeholder, setPlaceholder] = useState("")
  const [target, setTarget] = useState("")

  const { addToast } = useToasts();

  const formik = useFormik({
    initialValues: receiverDetails,
    validationSchema: receiverSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,

    onSubmit: (values) => {
      const receiverDetails = {
        ...values,
        _id: "DD_" + generateUniqueID(),
        shipmentId:
          (isLocal && Array.isArray(JSON.parse(localStorage?.getItem("shipmentDetails")))) ?
          JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id : data?.shipmentDetails?._id,
      };
      const existingArray =
        isLocal ? JSON.parse(localStorage.getItem("receiverDetails")) || [] : data?.receiverDetails;
      existingArray.push(receiverDetails);
      if(isLocal) {
        localStorage.setItem("receiverDetails", JSON.stringify(existingArray));
      } else {
        setData({...data, receiverDetails: existingArray})
      }
      addToast("Successfully added receiver details", {
        appearance: "success",
        autoDismiss: true,
      });
      if (flag) {
        formik.resetForm();
      }
      setFlag(false);
      setChangeAnyThing(true);
    },
  });

  useEffect(() => {
    receiverAddress.address &&
      formik.setFieldValue("address", receiverAddress.address);
    receiverAddress.city && formik.setFieldValue("city", receiverAddress.city);
    receiverAddress.state &&
      formik.setFieldValue("state", receiverAddress.state);
    receiverAddress.country &&
      formik.setFieldValue("country", receiverAddress.country);
    receiverAddress.postCode &&
      formik.setFieldValue("postcode", receiverAddress.postCode);
    receiverAddress.latlng &&
      formik.setFieldValue("lat", receiverAddress.latlng?.[0]);
    receiverAddress.latlng &&
      formik.setFieldValue("lng", receiverAddress.latlng?.[1]);
  }, [receiverAddress]);

  function checkAddressExists(array, address) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].address === address) {
        return true;
      }
    }
    return false;
  }

  const searchHandler = () => {
    const storedReceivers = JSON.parse(localStorage.getItem("receiverDetails")) || [];
    let isAddressPresent = checkAddressExists(storedReceivers, formik.values.address)
    if(isAddressPresent) {
      addToast("Delivery already added please choose different address", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      formik.handleSubmit();
    }
  };
 
  const addMore = () => {
    const storedReceivers = isLocal ? JSON.parse(localStorage.getItem("receiverDetails")) || [] : data?.receiverDetails;
    let isAddressPresent = checkAddressExists(storedReceivers, formik.values.address)
    if(isAddressPresent) {
      addToast("Delivery already added please choose different address", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      formik.handleSubmit();
      setReceiverAddress({});
      setFlag(true);
    }
  };

  function handleCustomerSelect(customer) {
    formik.setFieldValue(
      "name",
      customer ? customer.name : ""
    );
    formik.setFieldValue(
      "email",
      customer ? customer.email : ""
    );
    formik.setFieldValue(
      "address",
      customer ? customer.address : ""
    );
    formik.setFieldValue(
      "state",
      customer ? customer.state : ""
    );
    formik.setFieldValue(
      "city",
      customer ? customer.city : ""
    );
    formik.setFieldValue(
      "country",
      customer ? customer.country : ""
    );
    formik.setFieldValue(
      "postcode",
      customer ? customer.postcode : ""
    );
    formik.setFieldValue(
      "phone",
      customer ? customer.phone : ""
    );
    formik.setFieldValue(
      "lat",
      customer ? customer.lat : ""
    );
    formik.setFieldValue(
      "lng",
      customer ? customer.lng : ""
    );
    formik.setFieldValue(
      "company",
      customer ? customer?.company : ""
    );
  }

  const autocompleteRef = React.useRef(null);

  const onAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const location = place.geometry.location;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: {lat: location.lat(), lng: location.lng()} }, (results, status) => {
        if (status === "OK") {
          formik.setFieldValue('address',place?.name + " " + place?.formatted_address);
          formik.setFieldValue('lat',location.lat());
          formik.setFieldValue('lng',location.lng());
          extractAddressComponents(results[0]);
        }
      });
      
    }
  };

  const extractAddressComponents = (result) => {
    const components = result.address_components;
    let city = "";
    let state = "";
    let postcode = "";
    let country = "";

    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      const types = component.types;

      if (types.includes("locality")) {
        city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (types.includes("postal_code")) {
        postcode = component.long_name;
      } else if (types.includes("country")) {
        country = component.long_name;
      }
    }

    formik.setFieldValue('city', city)
    formik.setFieldValue('state', state)
    formik.setFieldValue('postcode', postcode)
    formik.setFieldValue('country', country)
  };

  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    let script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.addEventListener("load", () => setApiLoaded(true));
    document.body.appendChild(script);
  }, []);

  if (!apiLoaded) return null;

  return apiLoaded && (
    <div className="shipment__card br-tr br-br">
      <SearchCustomerModal 
        searchCustomer={searchCustomer}
        setSearchCustomer={setSearchCustomer}
        formik={formik}
        handleCustomerSelect={(customer) => handleCustomerSelect(customer)}
        placeholder={placeholder}
        url={"api/shipping/delivery/getByNameOrEmail"}
        target={target}
        searchFor={"delivery"}
      />
      <div className="shipment__card__header">
        <h3>Reciever Details ({dataLength})</h3>
      </div>
      {showTime && (
        <div className="modalMask">
          <div className="modalWrapper">
            <TimeKeeper
              time={time}
              onChange={(newTime) => {
                formik.setFieldValue("time", newTime.formatted12);
                setStartTime(newTime.formatted12);
              }}
              onDoneClick={() => setShowTime(false)}
              switchToMinuteOnHourSelect
            />
          </div>
        </div>
      )}
      <div className="card__body">
        <div className="flex-input-wrap">
          <label htmlFor="" className="grid-label2">
            Shipment Id:{" "}
          </label>
          <div
            className={
              formik.errors.shipmentId
                ? "error-border assign_rate_with_logo input__with__error"
                : "assign_rate_with_logo input__with__error"
            }
          >
            <input
              type="text"
              placeholder="Enter shipment ID"
              value={
                !isLocal && data ? data?.shipmentDetails?._id :
                Array.isArray(
                  JSON.parse(localStorage?.getItem("shipmentDetails"))
                ) ?
                JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id
                  : ""
              }
              onClick={() => {
                if(isLocal) {
                  if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                    setFocusSave(true);
                  } else {
                    setFocusSave(false);
                  }
                }
              }}
              readOnly
            />
          </div>
        </div>
        <div className="grid-2">
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              Name:{" "}
            </label>
            <div className="flex-input">
              <div
                className={
                  formik.errors.name
                    ? "error-border assign_rate_with_logo input__with__error"
                    : "assign_rate_with_logo input__with__error"
                }
                style={{borderRadius: "5px 0 0 5px" , borderRight: "none"}}
              >
                <input
                  type="text"
                  placeholder="Enter name"
                  value={formik.values.name}
                  onChange={(e) => formik.setFieldValue("name", e.target.value)}
                  onClick={() => {
                    if(isLocal) {
                      if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                        setFocusSave(true);
                      } else {
                        setFocusSave(false);
                      }
                    }
                  }}
                />
                <span>{formik.errors.name}</span>
              </div> 
              <div className="input-with-icon">
                  <RiSearchLine onClick={() => {
                      setPlaceholder("Search customer by name")
                      setSearchCustomer(true)
                      setTarget("name")
                    }}
                  />
              </div>
            </div>
          </div>
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              Email:{" "}
            </label>
            <div className="flex-input">
              <div
                className={
                  formik.errors.email
                    ? "error-border assign_rate_with_logo input__with__error"
                    : "assign_rate_with_logo input__with__error"
                }
                style={{borderRadius: "5px 0 0 5px", borderRight: "none"}}
              >
                <input
                  type="text"
                  placeholder="Enter email"
                  value={formik.values.email}
                  onChange={(e) => formik.setFieldValue("email", e.target.value)}
                  onClick={() => {
                    if(isLocal) {
                      if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                        setFocusSave(true);
                      } else {
                        setFocusSave(false);
                      }
                    }
                  }}
                />
                <span>{formik.errors.email}</span>
              </div>
              <div className="input-with-icon">
                  <RiSearchLine onClick={() => {
                    setPlaceholder("Search customer by email")
                    setSearchCustomer(true)
                    setTarget("email")
                  }}/>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-2">
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              Date:{" "}
            </label>
            <div
              className={
                formik.errors.date
                  ? "error-border assign_rate_with_logo input__with__error"
                  : "assign_rate_with_logo input__with__error"
              }
            >
              <input
                type="date"
                min={new Date().toISOString().split("T")[0]}
                placeholder="Select Date"
                value={formik.values.date}
                onChange={(e) => formik.setFieldValue("date", e.target.value)}
                onClick={() => {
                  if(isLocal) {
                    if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                      setFocusSave(true);
                    } else {
                      setFocusSave(false);
                    }
                  }
                }}
              />
              <span>{formik.errors.date}</span>
            </div>
          </div>
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              Time:{" "}
            </label>
            <div
              className={
                formik.errors.time
                  ? "error-border assign_rate_with_logo input__with__error"
                  : "assign_rate_with_logo input__with__error"
              }
            >
              <input
                type="text"
                value={startTime}
                placeholder="Set Time"
                onClick={() => {
                  if(isLocal) {
                    if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                      setFocusSave(true);
                    } else {
                      setFocusSave(false);
                      setShowTime(true)
                    }
                  } else if(!isLocal){
                    setShowTime(true);
                  }
                }}
              />
              <span>{formik.errors.time}</span>
            </div>
          </div>
        </div>
        <div className="grid-2">
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              Phone:{" "}
            </label>
            <div
              className={
                formik.errors.phone
                  ? "error-border assign_rate_with_logo input__with__error"
                  : "assign_rate_with_logo input__with__error"
              }
            >
              <input
                type="number"
                placeholder="Enter phone"
                value={formik.values.phone}
                onChange={(e) => formik.setFieldValue("phone", e.target.value)}
                onClick={() => {
                  if(isLocal) {
                    if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                      setFocusSave(true);
                    } else {
                      setFocusSave(false);
                    }
                  }
                }}
              />
              <span>{formik.errors.phone}</span>
            </div>
          </div>
          <div className="flex-input-wrap">
              <label htmlFor="" className="grid-label2">
                Addess:{" "}
              </label>
              <div className="flex">
                <div className="flex-input">
                  <div
                    className={
                      formik.errors.address
                        ? "error-border assign_rate_with_logo input__with__error"
                        : "assign_rate_with_logo input__with__error"
                    }
                    style={{borderRadius: "5px 0 0 5px", borderRight: "none"}}
                  >
                    <Autocomplete
                      onLoad={onAutocompleteLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <input
                        type="text"
                        placeholder="Enter address"
                        value={formik.values.address}
                        onChange={(e) => formik.setFieldValue("address", e.target.value)}
                        onClick={() => {
                          if(isLocal) {
                            if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                              setFocusSave(true);
                            } else {
                              setFocusSave(false);
                            }
                          }
                        }}
                      />
                    </Autocomplete>
                    <span>{formik.errors.address}</span>
                  </div>
                  <div className="input-with-icon" onClick={() => setAddressMapToggleReceiver(true)}>
                    <BsPinAngle />
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div className="grid-2">
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              City:{" "}
            </label>
            <div
              className={
                formik.errors.city
                  ? "error-border assign_rate_with_logo input__with__error"
                  : "assign_rate_with_logo input__with__error"
              }
            >
              <input
                type="text"
                placeholder="Enter city"
                value={formik.values.city}
                onChange={(e) => formik.setFieldValue("city", e.target.value)}
                onClick={() => {
                  if(isLocal) {
                    if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                      setFocusSave(true);
                    } else {
                      setFocusSave(false);
                    }
                  }
                }}
                readOnly
              />
              <span>{formik.errors.city}</span>
            </div>
          </div>
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              State:{" "}
            </label>
            <div
              className={
                formik.errors.state
                  ? "error-border assign_rate_with_logo input__with__error"
                  : "assign_rate_with_logo input__with__error"
              }
            >
              <input
                type="text"
                placeholder="Enter State"
                value={formik.values.state}
                onChange={(e) => formik.setFieldValue("state", e.target.value)}
                onClick={() => {
                  if(isLocal) {
                    if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                      setFocusSave(true);
                    } else {
                      setFocusSave(false);
                    }
                  }
                }}
                readOnly
              />
              <span>{formik.errors.state}</span>
            </div>
          </div>
        </div>
        <div className="grid-2">
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              Postcode:{" "}
            </label>
            <div
              className={
                formik.errors.postcode
                  ? "error-border assign_rate_with_logo input__with__error"
                  : "assign_rate_with_logo input__with__error"
              }
            >
              <input
                type="text"
                placeholder="Enter postcode"
                value={formik.values.postcode}
                onChange={(e) => formik.setFieldValue("postcode", e.target.value)}
                onClick={() => {
                  if(isLocal) {
                    if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                      setFocusSave(true);
                    } else {
                      setFocusSave(false);
                    }
                  }
                }}
                readOnly
              />
              <span>{formik.errors.postcode}</span>
            </div>
          </div>
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              Country:{" "}
            </label>
            <div
              className={
                formik.errors.country
                  ? "error-border assign_rate_with_logo input__with__error"
                  : "assign_rate_with_logo input__with__error"
              }
            >
              <input
                type="text"
                placeholder="Enter country"
                value={formik.values.country}
                onChange={(e) => formik.setFieldValue("country", e.target.value)}
                onClick={() => {
                  if(isLocal) {
                    if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                      setFocusSave(true);
                    } else {
                      setFocusSave(false);
                    }
                  }
                }}
                readOnly
              />
              <span>{formik.errors.country}</span>
            </div>
          </div>
        </div>
        <div className="grid-2">
          <div className="flex-input-wrap">
            <label htmlFor="" className="grid-label2">
              Company Name:{" "}
            </label>
            <div
              className={
                formik.errors.company
                  ? "error-border assign_rate_with_logo input__with__error"
                  : "assign_rate_with_logo input__with__error"
              }
            >
              <input
                type="text"
                placeholder="Enter company"
                value={formik.values.company}
                onChange={(e) => formik.setFieldValue("company", e.target.value)}
                onClick={() => {
                  if(isLocal) {
                    if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                      setFocusSave(true);
                    } else {
                      setFocusSave(false);
                    }
                  }
                }}
              />
              <span>{formik.errors.company}</span>
            </div>
          </div>
          <div className="flex-input-wrap">
          <label htmlFor="" className="grid-label2">
            Special Instructions:{" "}
          </label>
          <div
            className={
              formik.errors.specialInstructions
                ? "error-border assign_rate_with_logo input__with__error"
                : "assign_rate_with_logo input__with__error"
            }
          >
            <input
              type="text"
              placeholder="Type here"
              value={formik.values.specialInstructions}
              onClick={() => {
                if(isLocal) {
                  if(!JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id) {
                    setFocusSave(true);
                  } else {
                    setFocusSave(false);
                  }
                }
              }}
              onChange={(e) =>
                formik.setFieldValue("specialInstructions", e.target.value)
              }
            />
            <span>{formik.errors.specialInstructions}</span>
          </div>
          </div>
        </div>
        <div className="flex-justify-center">
          <button className="pickup_save_btn" onClick={addMore}>
            Save
          </button>
          {/* <button className="pickup_save_btn" onClick={searchHandler}>
            Save
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ReceiverDetails;
