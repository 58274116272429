import React, {useEffect} from "react";
import {
  Button,
  Form,
  Spinner
} from "reactstrap";

import logo from "../../images/tracking/track_image.svg"
import "./styles/resetPassword.css";
import LoginModel from "../../components/molecules/loginModel";
import "font-awesome/css/font-awesome.min.css";

import { useNavigate, Link } from "react-router-dom";

import { useResetPasswordInfo } from "./useResetPassword";
import {getVerifyToken} from "../../helpers/localStorage";


const ResetPassword = (props) => {
  const {
    modelShow,
    validEye,
    validationMsg,
    inputRef,
    passwordShown,
    confirmPasswordShown,
    modelFunc,
    SetPasswordFun,
    togglePassword,
    toggleConfirmPassword,
    handleFoucs,
    loading,
    inputRefs,
    handleInputChange,
    newPasswordFunc,
    confirmPasswordFunc,
    otpValue,
    newPassword,
    confirmPassword
  } = useResetPasswordInfo(props);

  const navigate = useNavigate();
  const token = getVerifyToken();

  useEffect(() => {
    if(token){
      navigate("/dashboard")
    }
  }) 

  return (
    <>
      <div fluid className='login_wrapper'>
        <div className="login_card">
          <div className="lglogin ">
            <img src={logo} alt="logo"/>
            <p>Go Trucking</p>  
          </div>
          <h4 className="sign-in">Set your new password!</h4>
          <p className="welcome">Change your password first need to enter otp, so we verify its really you.</p>
          <Form onSubmit={SetPasswordFun} style={{width: "300px", margin: "0 auto"}}>
            <>
              <div className="inputs">
                <span className="otp-label">Enter OTP</span>
                <div className="otp-input-container">
                
                {inputRefs.map((inputRef, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      ref={inputRef}
                      onChange={(e) => handleInputChange(e, index)}
                      className="otp-input"
                    />
                  ))}
                </div>
                
                <div className="input-box relative">
                  <p className="login-label">New PASSWORD</p>
                  <input
                    id="example-date-input-password"
                    onChange={(e) => newPasswordFunc(e)}
                    // className={`${validation}`}
                    onFocus={() => handleFoucs()}
                    type={passwordShown ? "text" : "password"}
                    ref={inputRef}
                  />
                  <i
                    onClick={() => togglePassword()}
                    className={`fa ${validEye} ${
                      !passwordShown ? "fa-eye-slash" : "fa-eye"
                    }`}
                  ></i>
              </div>
              <div className="input-box relative">
                  <p className="login-label">Confirm PASSWORD</p>
                  <input
                    id="example-date-input-password"
                    onChange={(e) => confirmPasswordFunc(e)}
                    // className={`${validation}`}
                    onFocus={() => handleFoucs()}
                    type={confirmPasswordShown ? "text" : "password"}
                    ref={inputRef}
                  />
                  <i
                    onClick={() => toggleConfirmPassword()}
                    className={`fa ${validEye} ${
                      !confirmPasswordShown ? "fa-eye-slash" : "fa-eye"
                    }`}
                  ></i>
              </div>
              </div>
              <p className={`${validationMsg}`}>
              </p>
            </>
            <div className="btn_wrap">
              <Button disabled={loading || otpValue.join("").length < 4 || !newPassword || !confirmPassword || confirmPassword !== newPassword} className="waves-effect waves-light lgbtn login__btn">
              {
                loading ? <Spinner color="light" size="sm" /> : "Change Password"
              }
              </Button>
            </div>
          </Form>
          <div style={{textAlign:"center"}}>
    
              <div className="route-link">
                {localStorage.getItem("forget-email")} not your email?
                <br></br>
              <Link to="/forget-password">
               Go Back
              </Link>
            </div>
          </div>
          
        </div>
      </div>
      <div className="mob-img"></div>
      <LoginModel show={modelShow} setModelShow={modelFunc} />
    </>
  );
};

export default ResetPassword;
