import * as yup from "yup";

const shipmentSchema = yup
  .object()
  .shape({
    customer: yup.mixed().required("*required"),
    customerId: yup.mixed(),
    customerRate: yup.mixed(),
    assignTo: yup.object().shape({
      label: yup.mixed().required("*required"),
      value: yup.mixed().required("*required"),
    }),
    contractor: yup.object().shape({
      id: yup.mixed(),
      name: yup.mixed(),
    }),
    //   track_driver: yup.boolean,
    assignRate: yup.mixed(),
    expense: yup.mixed(),
    expenseCurrency: yup.mixed(),
    currency: yup.mixed(),
    truck_type: yup.mixed(),
    truck_number: yup.mixed(),
    trailer_number: yup.mixed(),
    clientReference: yup.mixed(),
  })
  .test("contractor", "Contractor is required", function (value) {
    const { assignTo, contractor } = value;
    if (
      assignTo.value === "contractor" &&
      (!contractor.id || !contractor.name)
    ) {
      return this.createError({
        path: "contractor",
        message: "*required",
      });
    }
    return true;
  });

const pickupSchema = yup.object().shape({
  name: yup.string().required("*Name is required"),
  phone: yup.number("*must be number").required("*phone is required"),
  email: yup.string().email("*must be a valid email").required("*email is required"),
  address: yup.string().required("*address is required"),
  city: yup.string(),
  state: yup.string(),
  date: yup.date().required("*required"),
  time: yup.string().required("*required"),
  postcode: yup.mixed(),
  country: yup.string(),
  company: yup.mixed(),
  specialInstructions: yup.mixed(),
  lat: yup.number(),
  lng: yup.number(),
});

const receiverSchema = yup.object().shape({
  name: yup.string().required("*Name is required"),
  phone: yup.number("*must be number").required("*phone is required"),
  email: yup.string().email("*must be a valid email").required("*email is required"),
  address: yup.string().required("*address is required"),
  city: yup.string(),
  state: yup.string(),
  date: yup.date().required("*required"),
  time: yup.string().required("*required"),
  postcode: yup.mixed(),
  country: yup.string(),
  company: yup.mixed(),
  specialInstructions: yup.mixed(),
  lat: yup.number(),
  lng: yup.number(),
});

export { shipmentSchema, pickupSchema, receiverSchema };
