import React, { useState, useEffect } from "react";
import "../css/shipment.css";
import dollar_img from "../../../images/shipment/dollar.svg";
import pounds_img from "../../../images/shipment/euro.svg";
import cross from "../../../images/icons/cross-white.svg";
import imageModal from "../../../images/modal/images.jpg";
import UserModal from "../Modals/UserModal";
import ExpenseModal from "../Modals/ExpenseModal";
import { useFormik } from "formik";
import { shipmentSchema } from "../Validations";
import Dropdown from "../../../components/Select";
import { useToasts } from "react-toast-notifications";
import { useQuery } from "react-query";
import { getCustomerFunc } from "../../../ApIs/customers";
import { findContractor } from "../../../ApIs/shipping";
import avatar from "../../../images/user-placeholder.png";
import { BsCurrencyEuro } from "react-icons/bs";
import DriverSearchModal from "../Modals/DriverSearchModal";
import DriverSearchProfile from "../Modals/DriverSearchProfile";
import * as yup from "yup";
import ErrorModal from "../Modals/ErrorModal";
import InvitationModal from "../Modals/InvitationModal";
import SearchCustomerModal from "../Modals/SearchCustomerModal";

const CurrencyOptions = [
  { label: "dollar", value: "dollar" },
  { label: "pounds", value: "pounds" },
  { label: "euro", value: "euro" },
];

const AssignOptions = [
  { label: "contractor", value: "contractor" },
  { label: "own driver", value: "own_driver" },
];
const defaultCurrency = { label: "pounds", value: "pounds" };
const defaultType = { label: "contractor", value: "contractor" };

const DropdownIndicator = () => null;
const IndicatorSeparator = () => null;

const shipmentDetailsSchema = {
  customer: "",
  customerId: "",
  customerRate: "",
  assignTo: { label: "own driver", value: "own_driver" },
  assignRate: "",
  customerObj: {
    label: "",
    value: "",
  },
  contractor: {
    id: "",
    name: "",
  },
  expense: "",
  currency: "pounds",
  expenseCurrency: "pounds",
  truck_type: "",
  truck_number: "",
  trailer_number: "",
  profit: "",
  track_driver: true,
  clientReference: ""
};

const invitationSchema = {
  name: "",
  email: "",
};

const invitationValidation = yup.object().shape({
  name: yup.string().required("*name is required"),
  email: yup
    .string()
    .email("*must be a valid email")
    .required("*email is required"),
});

function generateUniqueID() {
  const randomPart = Math.random().toString(36).substr(2,3).toUpperCase();
  const timePart = Date.now().toString(36).substr(-3).toUpperCase(); 
  const uniqueID = `${timePart}${randomPart}`;
  return uniqueID;
}

const uniqueID = "SP_" + generateUniqueID();

async function currencyConverter(currency, expenseCurrency, expense){
  const currencyCode = currency === "dollar" ? "USD" : currency === "pounds" ? "GBP" : "EUR";
  const expenseCurrencyCode = expenseCurrency === "dollar" ? "USD" : expenseCurrency === "pounds" ? "GBP" : "EUR";

  const options = {method: 'GET', headers: {accept: 'application/json'}};
  
  try {
    const response = await fetch(`${process.env.REACT_APP_FAST_FOREX_URL}convert?from=${expenseCurrencyCode}&to=${currencyCode}&amount=${expense}&api_key=${process.env.REACT_APP_FAST_FOREX_KEY}`, options);
    const data = await response.json();
    const firstKey = Object.keys(data?.result)[0];
    const res = data?.result[firstKey];
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }
}

const ShipmentDetails = ({
  setShipmentId,
  shipmentId,
  setChangeAnyThing,
  focusSave,
  setFocusSave,
  data,
  isLocal,
  setData,
  storage
}) => {
  const [shipmentDetails, setShipmentDetails] = useState(shipmentDetailsSchema);
  const [invitationDetails, setInvitationDetails] = useState(invitationSchema);
  const [disabled, setDisabled] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);
  const [expense, setExpense] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState(false);
  const [profit, setProfit] = useState(Number);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [contractor, setContractor] = useState("");
  const [vat, setVat] = useState("");
  const { addToast } = useToasts();
  const token = localStorage?.getItem("token");


  // Modal States
  const [userModal, setUserModal] = useState(false);
  const [searchDriverModal, setSearchDriverModal] = useState(false);
  const [searchDriverProfile, setSearchDriverProfile] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [expenseModal, setExpenseModal] = useState(false);
  const [searchError, setSearchError] = React.useState(false);
  const [invitationModal, setInvitationModal] = React.useState(false);
  const [searchCustomer, setSearchCustomer] = useState(false);
  const [convertedExpense, setConvertedExpense] = useState(0)


  const currencyHandler = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    formik.setFieldValue('currency', selectedOption.value)
  };
  const { data: allCustomers, refetch } = useQuery(["customers"], () =>
    fetchAllCustomers()
  );
  const fetchAllCustomers = () => getCustomerFunc(token);
  const AllCustomersData = allCustomers?.data?.customers;
  

  useEffect(() => {
    if(!isLocal && data) {
      const dataClone = data?.shipmentDetails;
      setShipmentDetails({
        customerRate: +dataClone?.customerRate || "",
        assignRate: +dataClone?.assignRate || "",
        customer: dataClone?.customerObj?.name || "",
        customerId: dataClone?.customerObj?._id || "",
        assignTo: { label: dataClone?.contractor?.name ? "contractor" : "own driver", value: dataClone?.contractor?.name ? "contractor" : "own_driver" },
        customerObj: {
          label: "",
          value: "",
        },
        contractor: {
          id: dataClone?.contractor?._id || "",
          name: dataClone?.contractor?.name || "",
        },
        expense: +dataClone?.expense || "",
        currency: dataClone?.currency || "pounds",
        expenseCurrency: dataClone?.expenseCurrency || "pounds",
        truck_type: dataClone?.truck_type || "",
        truck_number: dataClone?.truck_number || "",
        clientReference: dataClone?.clientReference || "",
        trailer_number: dataClone?.trailer_number || "",
        profit: +dataClone?.profit || "",
        track_driver: true,
      })
    } else if (
      Array.isArray(JSON.parse(localStorage?.getItem("shipmentDetails"))) &&
      JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0] && isLocal
    ) {
      setShipmentDetails(
        JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]
      );
    } else {
      setShipmentDetails(shipmentDetailsSchema);
    }
  }, [data, isLocal, storage]);

  useEffect(() => {
    if (focusSave) {
      let tenPercentHeight = window.innerHeight * 0.25;
      window.scrollTo({ top: tenPercentHeight, behavior: "smooth" });
    }
  }, [focusSave]);

  const searchHandler = () => {
    window.scrollTo(0, 0);
    setFocusSave(false);
    if (
      !errors?.assignRate ||
      !errors?.contractor ||
      !errors?.assignTo ||
      !errors?.customer ||
      !errors?.customerId ||
      !errors?.customerObj ||
      !errors?.customerRate ||
      !errors?.expense ||
      !errors?.profit ||
      !errors?.track_driver
    ) {
      setFormValue(true);
      handleSubmit();
    }
  };

  useEffect(() => {
    refetch();
  }, [userModal]);

  const document = JSON.parse(localStorage?.getItem("shipmentDetails")) || [];

  const {
    values: inviteValues,
    errors: inviteErrors,
    resetForm: inviteReset,
    handleBlur,
    handleChange,
    handleSubmit: inviteSubmit,
  } = useFormik({
    initialValues: invitationDetails,
    validationSchema: invitationValidation,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,

    onSubmit: (values) => {
      sendInvitation(values);
    },
  });

  const formik = useFormik({
    initialValues: profit
      ? {
          ...shipmentDetails,
          profit: profit ? profit : "",
        }
      : shipmentDetails,
    validationSchema: shipmentSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,

    onSubmit: (values) => {
      if (
        Array.isArray(
          JSON.parse(localStorage?.getItem("shipmentDetails")) || []
        ) && isLocal
      ) {
        if (
          (JSON.parse(localStorage?.getItem("shipmentDetails")) || [])
            ?.length === 0
        ) {
          localStorage?.setItem("shipmentId", uniqueID);
          const shipmentDetails = {
            ...values,
            _id: localStorage?.getItem("shipmentId"),
            profit: (+values?.customerRate - convertedExpense - +values.assignRate).toFixed(2)
          };
          const existingArray =
            JSON.parse(localStorage?.getItem("shipmentDetails")) || [];
          existingArray.push(shipmentDetails);
          localStorage?.setItem(
            "shipmentDetails",
            JSON.stringify(existingArray)
          );
          addToast("Successfully saved new shipment details", {
            appearance: "success",
            autoDismiss: true,
          });
          setChangeAnyThing(true);
          setShipmentId(localStorage?.getItem("shipmentId"));
        } else {
          const documents = JSON.parse(localStorage?.getItem("shipmentDetails"));
          if (Array.isArray(documents)) {
            const modifiedData = documents.slice(1);
            localStorage?.setItem(
              "shipmentDetails",
              JSON.stringify(modifiedData)
            );
          }

          const shipmentDetails = {
            ...values,
            _id: localStorage?.getItem("shipmentId"),
            profit: (+values?.customerRate - convertedExpense - +values.assignRate).toFixed(2)
          };

          const existingArray =
            JSON.parse(localStorage?.getItem("shipmentDetails")) || [];
          existingArray.push(shipmentDetails);
          localStorage?.setItem(
            "shipmentDetails",
            JSON.stringify(existingArray)
          );
          addToast("Successfully edited shipment details", {
            appearance: "success",
            autoDismiss: true,
          });
          setShipmentId(localStorage?.getItem("shipmentId"));
        }
      } else {
        const valuesClone = {...values};
          // valuesClone.assignTo = data?.shipmentDetails?.assignTo;
          valuesClone.contractor = {
            _id: values?.contractor.id,
            name: values?.contractor?.name
          }
          valuesClone.assignTo = values?.contractor.id
          valuesClone.customerObj = {
            name: values?.customer,
            _id: values?.customerId,
          }
        setData({...data, shipmentDetails: {
          ...data?.shipmentDetails,
          ...valuesClone,
          profit: (+values?.customerRate - convertedExpense - +values.assignRate).toFixed(2)
        }})   
        addToast("Successfully edited shipment details", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    },
  });
  const { values, handleSubmit, setFieldValue, errors, resetForm, isValid } =
    formik;

  const handleCustomerSelect = (selectedOption) => {
    setSelectedCustomer(selectedOption);
    formik.setFieldValue(
      "customer",
      selectedOption
        ? selectedOption.firstName + " " + selectedCustomer.lastName
        : ""
    );
    formik.setFieldValue(
      "customerId",
      selectedOption ? selectedOption._id : ""
    );
    formik.setFieldValue("customerObj", selectedOption);
  };

  useEffect(() => {
    const data = JSON.parse(localStorage?.getItem("new-customer")) ? JSON.parse(localStorage?.getItem("new-customer")) : null;
    if (data?.data) {
      setSelectedCustomer(data?.data);
      formik.setFieldValue(
        "customer",
        data?.data ? data?.data?.firstName + " " + data?.data.lastName : ""
      );
      formik.setFieldValue("customerId", data?.data ? data?.data?._id : "");
      formik.setFieldValue("customerObj", data?.data);
      localStorage?.removeItem("new-customer");
    }
  }, [userModal, formik]);

  const getContractor = async () => {
    if (vat) {
      const contractor = await findContractor(token, vat);
      if (!contractor?.data?.success) {
        setSearchError(true);
        setSearchDriverModal(false);
      } else {
        setSearchDriverModal(false);
        setVat("");
        setContractor(contractor?.data?.user);
        setSearchDriverProfile(true);
      }
    }
  };

  const sendInvite = () => {
    setSearchError(false);
    setInvitationModal(true);
  };

  useEffect(() => {
    const newContractorData = JSON.parse(
      localStorage?.getItem("newContractorData")
    );
    const previousValue = JSON.parse(localStorage?.getItem("previousValue"));
    if (previousValue) {
      formik.setFieldValue("customer", previousValue?.customer);
      formik.setFieldValue("customerId", previousValue?.customerId);
      formik.setFieldValue("customerObj", previousValue?.customerObj);
      formik.setFieldValue("customerRate", previousValue?.customerRate);
      formik.setFieldValue("expense", previousValue?.expense);
      formik.setFieldValue("profit", previousValue?.profit);
      formik.setFieldValue("track_driver", previousValue?.track_driver);
      formik.setFieldValue("assignTo", previousValue?.assignTo);
      formik.setFieldValue("assignRate", previousValue?.assignRate);
      formik.setFieldValue("contractor", previousValue?.contractor);

      localStorage?.removeItem("previousValue");
    }
    if (newContractorData) {
      formik.setFieldValue("contractor", {
        id: newContractorData?.value,
        name: newContractorData?.label,
      });
      localStorage?.removeItem("newContractorData");
    }
  }, []);

  const assignContractor = () => {
    formik.setFieldValue("contractor", {
      id: contractor._id,
      name: contractor.firstName,
    });
    setSearchDriverProfile(false);
  };

  const sendInvitation = async ({ email, name }) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}api/users/invitation`;
      const formData = {
        type: "contractor",
        name: name,
        email: email,
      };

      const response = await fetch(url, {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data?.success) {
        setInvitationModal(false);
        addToast(data?.message, { appearance: "success", autoDismiss: true });
        inviteReset();
        setVat("");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const savePreviousValue = () => {
    localStorage?.setItem("previousValue", JSON.stringify(values));
    setSearchDriverModal(false);
    setInvitationModal(true);
  };

  const openExpenseModal = () => {
    setExpenseModal(true);
    if (
      localStorage?.getItem("expenses") === null ||
      JSON.parse(localStorage?.getItem("expenses")).length < 1
    ) {
      localStorage?.setItem(
        "expenses",
        JSON.stringify([{ type: "", value: 0 }])
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      const converted = await currencyConverter(values?.currency, values?.expenseCurrency, +values?.expense);
  
      if(converted > 0) {
        setConvertedExpense(converted);
      }
    }
    fetchData();
  }, [values?.expense, values?.currency, values?.expenseCurrency])

  return (
    <>
      <div className={focusSave ? "backdrop" : ""}></div>
      <ErrorModal
        searchError={searchError}
        setSearchError={setSearchError}
        setVat={setVat}
        sendInvite={sendInvite}
        setSearchDriverModal={setSearchDriverModal}
      />

      <InvitationModal
        invitationModal={invitationModal}
        setInvitationModal={setInvitationModal}
        setVat={setVat}
        inviteReset={inviteReset}
        inviteErrors={inviteErrors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        inviteValues={inviteValues}
        inviteSubmit={inviteSubmit}
        loading={loading}
      />

      <SearchCustomerModal
        searchCustomer={searchCustomer}
        setSearchCustomer={setSearchCustomer}
        formik={formik}
        handleCustomerSelect={handleCustomerSelect}
        placeholder={"Search by name or company"}
        url={"api/users/single-customer"}
        target={"name"}
        searchFor={"shipment"}
      />

      <div className="shipment__card relative br-tl br-bl border-l relative-position">
        <div className="shipment__card__header">
          <h3>Shipment Details</h3>
        </div>
        <div className="card__body">
          <div className="input__with__error">
            <div className="flex-wrap">
              <label htmlFor="" className="grid-label2">
                Name:{" "}
              </label>
              <div className="flex-input">
                <div
                  className={
                    formik.errors.name
                      ? "error-border assign_rate_with_logo input__with__error"
                      : "assign_rate_with_logo input__with__error"
                  }
                  style={{ borderRadius: "5px 0 0 5px", borderRight: "none" }}
                >
                  <input
                    style={{ cursor: "pointer" }}
                    type="text"
                    placeholder="Search by name or company"
                    value={formik.values.customer}
                    onClick={() => setSearchCustomer(true)}
                    onChange={(e) =>
                      formik.setFieldValue("customer", e.target.value)
                    }
                    readOnly
                  />
                  <span>{formik.errors.customer}</span>
                </div>
                <div
                  className="input-with-icon"
                  onClick={() => {
                    setUserModal(true);
                    document.body.style.overflowY = "hidden";
                  }}
                >
                  <p>Create New</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input__with__error">
            <div className="flex-wrap">
              <label htmlFor="" className="grid-label2">
                Customer Rate (Flat Rate)
              </label>
              <div className="flex-input">
                <div
                  className="assign_rate_with_logo"
                  style={{ borderRadius: "5px 0 0 5px" }}
                >
                  <input
                    type="text"
                    value={values.customerRate}
                    onChange={(e) =>
                      setFieldValue("customerRate", e.target.value)
                    }
                  />
                  {values?.currency === "pounds" ? (
                    <img src={pounds_img} alt="pounds" />
                  ) : values?.currency === "euro" ? (
                    <BsCurrencyEuro />
                  ) : (
                    <img src={dollar_img} alt="dollar" />
                  )}
                </div>
                <Dropdown
                  options={CurrencyOptions}
                  value={{label: values.currency, value: values.currency}}
                  onChange={currencyHandler}
                  isClearable={false}
                  components={{ IndicatorSeparator }}
                  error={false}
                  joinedSelectLeft={true}
                />
              </div>
            </div>
            <span>{errors.customerRate}</span>
          </div>
          <div className="input__with__error">
            <div className="flex-wrap">
              <label htmlFor="" className="grid-label2">
                Assign To:
              </label>
              <div className="flex-input">
                <Dropdown
                  options={AssignOptions}
                  value={values?.assignTo}
                  onChange={(e) => {
                    if (e.value === "own_driver") {
                      setFieldValue("profit", values.expense);
                      setFieldValue("assignRate", 0);
                      setFieldValue("contractor", { id: "", name: "" });
                    } else {
                      setSearchDriverModal(true);
                      localStorage?.setItem(
                        "previousValue",
                        JSON.stringify(values)
                      );
                    }
                    setFieldValue("assignTo", e);
                  }}
                  isClearable={false}
                  components={{ IndicatorSeparator }}
                  error={false}
                  joinedSelectRight={true}r
                />
                {formik?.values?.assignTo?.value === "contractor" ? (
                  <div
                    className="input-with-icon"
                    onClick={() => {
                        setSearchDriverModal(true);
                      localStorage?.setItem(
                        "previousValue",
                        JSON.stringify(values)
                      );
                    }}
                  >
                    <p>Assign To</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <span className="contractor__error">{errors.contractor}</span>
            </div>
          </div>
          {values?.contractor?.name &&
          values.assignTo.value === "contractor" ? (
            <div className="flex">
              <div
                className="flex-between"
                style={{ margin: "-12px 0 -7px 0" }}
              >
                <div className="contractor-display-pl">
                  <span>
                    <img src={avatar} alt="" />
                  </span>
                  <span className="contractor-name">
                    {formik?.values?.contractor?.name}
                  </span>
                </div>
                <div className="contrac-checkbox">
                  <input
                    readOnly
                    type="checkbox"
                    name="track_driver"
                    id="track_driver"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={isLocal ? formik.values.track_driver : true}
                    checked={isLocal ? formik.values.track_driver : true}
                  />
                  <label className="contrac-checkbox" htmlFor="trackDriver">
                    Track Driver
                  </label>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {values.assignTo.value === "contractor" && (
            <div className="input__with__error">
              <div className="flex-wrap">
                <label htmlFor="" className="grid-label2">
                  Assign Rate (Flat Rate)
                </label>
                <div className="assign_rate_with_logo">
                  <input
                    type="text"
                    value={values.assignRate}
                    placeholder="Enter assign rate"
                    onChange={(e) =>
                      setFieldValue("assignRate", e.target.value)
                    }
                  />
                  {selectedCurrency && selectedCurrency.value === "pounds" ? (
                    <img src={pounds_img} alt="pounds" />
                  ) : selectedCurrency && selectedCurrency.value === "euro" ? (
                    <BsCurrencyEuro />
                  ) : (
                    <img src={dollar_img} alt="dollar" />
                  )}
                  <span>{errors.assignRate}</span>
                </div>
              </div>
            </div>
          )}
          <div className="flex-wrap relative">
            <label htmlFor="" className="grid-label2">
              Expense
            </label>
            <div className="flex-input">
              <div
                className="assign_rate_with_logo"
                onClick={openExpenseModal}
                style={{ borderRadius: "5px 0 0 5px", borderRight: "none" }}
              >
                <input
                  type="text"
                  value={(+values.expense).toFixed(2)}
                  readOnly
                  placeholder="Enter expense"
                />
                {errors.expense ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "11px",
                      lineHeight: "0.1rem",
                      position: "absolute",
                      bottom: "-10px",
                      left: "0",
                    }}
                  >
                    {errors.expense}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="input-with-icon" onClick={openExpenseModal}>
                +
              </div>
            </div>
          </div>
          <div className="grid-2">
            <div className="flex-input-wrap">
              <label htmlFor="" className="grid-label2">
                Truck Type
              </label>
              <div
                className={
                  formik.errors.truck_type
                    ? "error-border assign_rate_with_logo input__with__error"
                    : "assign_rate_with_logo input__with__error"
                }
              >
                <input
                  type="text"
                  placeholder="Enter truck type"
                  value={formik.values.truck_type}
                  onChange={(e) =>
                    formik.setFieldValue("truck_type", e.target.value)
                  }
                />
                <span>{formik.errors.truck_type}</span>
              </div>
            </div>
            <div className="flex-input-wrap">
              <label htmlFor="" className="grid-label2">
                Truck Number
              </label>
              <div
                className={
                  formik.errors.truck_number
                    ? "error-border assign_rate_with_logo input__with__error"
                    : "assign_rate_with_logo input__with__error"
                }
              >
                <input
                  type="text"
                  placeholder="Enter truck number"
                  value={formik.values.truck_number}
                  onChange={(e) =>
                    formik.setFieldValue("truck_number", e.target.value)
                  }
                />
                <span>{formik.errors.truck_number}</span>
              </div>
            </div>
          </div>
          <div className="grid-2">
            <div className="flex-input-wrap">
              <label htmlFor="" className="grid-label2">
                Trailer Number
              </label>
              <div
                className={
                  formik.errors.trailer_number
                    ? "error-border assign_rate_with_logo input__with__error"
                    : "assign_rate_with_logo input__with__error"
                }
              >
                <input
                  type="text"
                  placeholder="Enter trailer number"
                  value={formik.values.trailer_number}
                  onChange={(e) =>
                    formik.setFieldValue("trailer_number", e.target.value)
                  }
                />
                <span>{formik.errors.trailer_number}</span>
              </div>
            </div>
            <div className="flex-input-wrap">
              <label htmlFor="" className="grid-label2">
                Our Profit
              </label>
              <div className="assign_rate_with_logo">
                <input
                  type="text"
                  value={
                    convertedExpense 
                    ? (+values?.customerRate - convertedExpense - +values.assignRate).toFixed(2)
                    : (+values?.customerRate - +values.expense - +values.assignRate).toFixed(2)
                  }
                  readOnly
                />
                {values?.currency === "pounds" ? (
                  <img src={pounds_img} alt="pounds" />
                ) : values?.currency === "euro" ? (
                  <BsCurrencyEuro />
                ) : (
                  <img src={dollar_img} alt="dollar" />
                )}
              </div>
            </div>
          </div>
          <div className="grid-2">
            <div className="flex-input-wrap">
              <label htmlFor="" className="grid-label2">
                Client Reference
              </label>
              <div
                className={
                  formik.errors.clientReference
                    ? "error-border assign_rate_with_logo input__with__error"
                    : "assign_rate_with_logo input__with__error"
                }
              >
                <input
                  type="text"
                  placeholder="Enter Client Reference"
                  value={formik.values.clientReference}
                  onChange={(e) =>
                    formik.setFieldValue("clientReference", e.target.value)
                  }
                />
                <span>{formik.errors.clientReference}</span>
              </div>
            </div>
          </div>
          <div className="flex-end-save">
            {Array.isArray(document) && document?.length === 0 && isLocal ? (
              <>
                <button
                  className="save_btn abs__btn__save"
                  onClick={searchHandler}
                  style={
                    values?.assignTo?.value === "contractor"
                      ? { marginTop: "10px", zIndex: "6" }
                      : { zIndex: "6" }
                  }
                >
                  Save
                </button>
                <div className="relative">
                  {focusSave && (
                    <div
                      className={
                        values?.assignTo?.value === "contractor"
                          ? "save_btn_popup_two"
                          : "save_btn_popup"
                      }
                    >
                      You must have to save shipment details before moving to
                      the next form.
                      <div className="corner"></div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <button
                  className="save_btn abs__btn__save"
                  onClick={searchHandler}
                  style={
                    values?.assignTo?.value === "contractor"
                      ? { marginTop: "10px", zIndex: "6" }
                      : { zIndex: "6" }
                  }
                >
                  Edit
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {/* CREATE USER MODAL */}
      {userModal && (
        <UserModal
          btnDisable={disabled}
          setUserModal={setUserModal}
          refetch={refetch}
        />
      )}
      {/* DRIVER SEARCH MODAL */}
      {/* {searchDriverModal && (
        <div className="modalMask">
          <div className="userModalWrapper">
            <div className="modal__header1">
              <h1>Assign Contractor</h1>
              <img
                src={cross}
                alt=""
                onClick={() => {
                  setSearchDriverModal(false);
                  setVat("");
                }}
              />
            </div>
            <div className="modal__body">
              <div className="contractor_body">
                <div>Please enter the given VAT Code</div>
              </div>
              <div className="contractor_input">
                <input
                  className="modal__input1"
                  type="text"
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                />
              </div>
              <div className="contractor_btn">
                <button onClick={getContractor} className="modal__btn1">
                  Search
                </button>
              </div>
              <div onClick={savePreviousValue} className="message_contractor">
                <div>Assign to unregistered contractor</div>
              </div>
            </div>
          </div>
        </div>
      )} */}

      <DriverSearchModal
        searchDriverModal={searchDriverModal}
        setSearchDriverModal={setSearchDriverModal}
        vat={vat}
        setVat={setVat}
        savePreviousValue={savePreviousValue}
        getContractor={getContractor}
      />

      {/* SearchDriverProfileModal */}

      <DriverSearchProfile
        searchDriverProfile={searchDriverProfile}
        imageModal={imageModal}
        contractor={contractor}
        setSearchDriverProfile={setSearchDriverProfile}
        assignContractor={assignContractor}
      />

      {/* DRIVER ASSIGN MODAL */}
      {assignModal && (
        <div className="modalMask">
          <div className="userModalWrapper">
            <div className="modal__header">
              <h1>Customer Details</h1>
              <img src={cross} alt="" onClick={() => setAssignModal(false)} />
            </div>
            <div className="modal__body"></div>
          </div>
        </div>
      )}

      {/* EXPENSE MODAL */}
      {expenseModal && (
        <ExpenseModal
          selectedCurrency={selectedCurrency}
          setExpenseModal={setExpenseModal}
          total={expense}
          setTotal={setExpense}
          formik={formik}
          isLocal={isLocal}
        />
      )}
    </>
  );
};

export default ShipmentDetails;
