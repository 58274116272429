/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/editTable.css";
import { useFormik } from "formik";
import { pickupSchema } from "../Validations";
import { useToasts } from "react-toast-notifications";
import delete_confirm from "../../../images/modals/delete.png"
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDots } from "react-icons/bs";
import EditPickupDetailModal from "../Modals/EditPickupDetail";

const EditableDataTable = ({
  setChangeAnyThing,
  changeAnyThing,
  setAddressMapTogglePickupTable,
  pickupAddressTable,
  setPickupAddressTable,
  data,
  isLocal,
  setData,
  storage
}) => {
  const [rowData, setRowData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editPickupPoint, setEditPickupPoint] = useState({});
  const [reloadData, setReloaDData] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [startTime, setStartTime] = React.useState("");
  const [showTime, setShowTime] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    const storedData = isLocal ? localStorage.getItem("pickupDetails") : data?.pickupDetails;
    if (storedData && isLocal) {
      setRowData(JSON.parse(storedData));
    } else if(data && !isLocal) {
      setRowData(storedData);
    }
    setChangeAnyThing(false);
  }, [changeAnyThing, reloadData, data?.pickupDetails, isLocal, storage]);

  const handleDelete = (row) => {
    setDeleteModal(true);
    setDeleteId(row?._id);
    setChangeAnyThing(true);
  };

  const deletePickupDetail = () => {
    const documents = isLocal ? JSON.parse(localStorage.getItem("pickupDetails")) : data?.pickupDetails;
    const filteredDocuments = documents.filter(
      (document) => document._id !== deleteId
    );
    if(isLocal) {
      localStorage.setItem("pickupDetails", JSON.stringify(filteredDocuments));
    } else if(!isLocal) {
      setData({...data, pickupDetails: filteredDocuments})
    }
    setChangeAnyThing(true);
    setRowData(filteredDocuments);
    setDeleteModal(false);
    setReloaDData(true);
    addToast("Successfully deleted", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const formik = useFormik({
    initialValues: editPickupPoint,
    validationSchema: pickupSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      const documents = isLocal ? JSON.parse(localStorage.getItem("pickupDetails")) || [] : data?.pickupDetails;
      const documentToUpdate = documents.find(
        (document) => document._id === values?._id
      );
      if (documentToUpdate) {
        documentToUpdate.name = values?.name;
        documentToUpdate.email = values?.email;
        documentToUpdate.address = values?.address;
        documentToUpdate.city = values?.city;
        documentToUpdate.state = values?.state;
        documentToUpdate.postcode = values?.postcode;
        documentToUpdate.country = values?.country;
        documentToUpdate.time = values?.time;
        documentToUpdate.date = values?.date;
        documentToUpdate.lat = values?.lat;
        documentToUpdate.lng = values?.lng;
        documentToUpdate.company = values?.company;
        documentToUpdate.specialInstructions = values?.specialInstructions;
      }
      setReloaDData(true);
      if(isLocal) {
        localStorage.setItem("pickupDetails", JSON.stringify(documents));
      } else {
        setData({...data, pickupDetails: documents})
      }
      setRowData(documents);
      addToast("Successfully edited pickup point details", {
        appearance: "success",
        autoDismiss: true,
      });
      setEditModal(false);
    },
  });

  const handleEdit = (row) => {
    setEditModal(true);
    setEditPickupPoint(row);
  };

  const [hoveredRowId, setHoveredRowId] = useState(null);

  const columns = [
    {
      name: "Shipment ID",
      selector: (row) => {
        return (
          <div>
            <div>
              {row.shipmentId}
            </div>
            {hoveredRowId === row._id && (
              <div className="actions__below">
                <span
                  onClick={() => handleEdit(row)}
                >
                  Edit
                </span>
                <span
                 onClick={() => handleDelete(row)}
                  className="delete_action"
                >
                  Delete
                </span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      name: "Pick-up Id",
      selector: (row) => row._id,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <div className="coloumn_text">
            {row.name}
          </div>
        )
      },
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Time",
      selector: (row) => row.time,
    },

    {
      name: "Address",
      width: "200px",
      selector: (row) => row.address,
    },
    {
      name: "Company",
      selector: (row) => row.company,
    },
    {
      name: "SpecialInstructions",
      selector: (row) => row.specialInstructions,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <>
            {/* <span onClick={() => handleEdit(row)} className="pad">
              <FiEdit className="edit-icon" />
            </span>
            <span onClick={() => handleDelete(row)} className="pad">
              <RiDeleteBin6Line className="delete-icon" />
            </span> */}
            <Dropdown dropleft="true">
              <Dropdown.Toggle
                className="mx-1 header-dropdown btn-primary custom-toggle"
                variant="secondary"
                id="dropdown-basic"
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  margin: "0",
                  padding: "0",
                }}
              >
                <BsThreeDots />
              </Dropdown.Toggle>
              <Dropdown.Menu menuPortalTarget={document.body}>
                <Dropdown.Item onClick={() => handleEdit(row)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(row)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const { values, errors, handleSubmit, handleChange } = formik;

  useEffect(() => {
    pickupAddressTable.address &&
      formik.setFieldValue("address", pickupAddressTable.address);
    pickupAddressTable.city &&
      formik.setFieldValue("city", pickupAddressTable.city);
    pickupAddressTable.state &&
      formik.setFieldValue("state", pickupAddressTable.state);
    pickupAddressTable.country &&
      formik.setFieldValue("country", pickupAddressTable.country);
    pickupAddressTable.postCode &&
      formik.setFieldValue("postcode", pickupAddressTable.postCode);
    pickupAddressTable.latlng &&
      formik.setFieldValue("lat", pickupAddressTable.latlng?.[0]);
    pickupAddressTable.latlng &&
      formik.setFieldValue("lng", pickupAddressTable.latlng?.[1]);
  }, [pickupAddressTable]);

  const customStyles = {
    table: {
      style: {
        height: `${rowData?.length > 2 ? "220px" : "180px"}`
      }
    },
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };
  
  return (
    <>
      <div
        className={`${rowData?.length > 2 ? "fixedHeight" : ""} border-table`}
      >
        <div className="paading-top package-details-heading">
          Pick-Up Points
        </div>
        <DataTable 
          columns={columns} 
          data={rowData} 
          customStyles={customStyles} 
          onRowMouseEnter={(row) => setHoveredRowId(row._id)}
          onRowMouseLeave={(row) => setHoveredRowId(null)}
        />
      </div>

      
      {/* EDIT MODAL */}
      <EditPickupDetailModal
        editModal={editModal}
        showTime={showTime}
        formik={formik}
        setShowTime={setShowTime}
        setStartTime={setStartTime}
        values={values}
        handleChange={handleChange}
        errors={errors}
        setAddressMapToggle={setAddressMapTogglePickupTable}
        handleSubmit={handleSubmit}
        setEditModal={setEditModal}
        setAddressTable={setPickupAddressTable}
        name="Pickup"
        data={data}
        setData={setData}
        isLocal={isLocal}
      />
      {/* DELETE MODAL */}
      {deleteModal && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img src={delete_confirm} alt="" style={{ width: "200px", height: "183px" }} />
              <p>Are you sure?</p>
              <p>
                You will lose all your contractor details.
              </p>
              <div className="edit__btns">
                <button className="edit_no" onClick={() => setDeleteModal(false)}>
                  No
                </button>
                <button
                  className={`edit_yes`}
                  onClick={deletePickupDetail}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditableDataTable;
