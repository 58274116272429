import axios from "axios";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const authApi = {
  login: async (body) => {
    try {
      return await request.post("api/users/login", body);
    } catch (error) {
      return error;
    }
  },
};

export default apiWrapper(authApi, ["login"]);
