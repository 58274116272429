import Dashboard from "../pages/dashboard";


import Profile from "../pages/Profile";
import Shipping from "../pages/Shipping";
import Invoices from "../pages/Orders/invoices";
import Admins from "../pages/Admins";
import Plans from "../pages/Plans";


const Pages = [
  {
    id: "1",
    path: "/admins",
    element: <Admins />,
  },
  {
    id: "2",
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    id: "3",
    path:"settings",
    element: <Profile />
  },
  {
    id: "4",
    path:"customers",
    element: <Admins />
  }, 
  {
    id: "5",
    path:"profile",
    element: <Profile />
  },
  {
    id: "6",
    path:"invoices",
    element: <Invoices />
  },
  {
    id: "7",
    path:"plans",
    element: <Plans />
  },
];  


export default Pages;
