import React, { useState } from 'react'
import { VscChromeClose } from "react-icons/vsc";
import modalImage1 from "../../images/modals/typewriter.svg";
import { useEffect } from 'react';
import Select from "react-select";
import { Country, State, City } from 'country-state-city';
import { AiOutlineLoading } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { getPlansFunc } from '../../ApIs/admins';


const FormModal = ({
    isEdit,
    setIsEdit,
    setCreateCustomer,
    data,
    formik,
    addCustomerLoading,
    editCustomerLoading,
    customerSchema,
    setInitailCustomer,
    setConfirmModal,
    countryCode,
    setCountryCode
}) => {

    const {
        handleChange,
        handleBlur,
        values,
        setValues,
        handleSubmit,
        errors,
    } = formik;

    const token = localStorage.getItem("token");
    const { data: plans } = useQuery(["plans"], () =>
        fetchAllPlans()
    );

    const durationList = [
        {label: 'MONTHLY', value: "monthly"},
        {label: 'YEARLY', value: "yearly"},
    ]

    const fetchAllPlans = () => getPlansFunc(token);
  
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [start, setStart] = useState(true);
    const [defaultPlan, setDefaultPlan] = useState(null);
    const [plansList, setPlansList] = useState([]);

    useEffect(() => {
        setValues({ ...values });
    }, [data]);

    useEffect(() => {
        if (isEdit && start) {
            setCountry({ value: values.country, label: values.country });
            setState({ value: values.state, label: values.state });
            setCity({ value: values.city, label: values.city });
        }
    }, [formik.values])
    
    useEffect(() => {
        if(isEdit) {
            const filteredPlan = plans?.data?.data.find(p => p._id === formik.values.plan)
            setDefaultPlan({value: filteredPlan?._id, label: filteredPlan?.name})
        }
    }, [formik.values, plans])

    useEffect(() => {
        const res = plans?.data?.data.map(({ name, _id }) => ({ value: _id, label: name }));
        setPlansList(res)
    }, [plans])

    // Get list of country, state and city
    const countryList = Country.getAllCountries().map(({ isoCode, name }) => ({ value: isoCode, label: name }));
    const stateList = country ? State.getStatesOfCountry(country.value).map(({ isoCode, name }) => ({ value: isoCode, label: name })) : [];
    const cityList = state ? City.getCitiesOfState(country.value, state.value).map(({ name }) => ({ value: name, label: name })) : [];

    useEffect(() => {
        const countries = Country.getAllCountries();
        const country = countries.find(country => country.name === formik.values.country);
        if (country) {
            setCountryCode(country.isoCode);
        }
    }, [formik.values.country]);

    const [disableOperation, setDisableOperation] = useState(false);
    const [disableFinance, setDisableFinance] = useState(false);


    const handleOperations = (e) => {
        if (e.target.checked) {
            setDisableOperation(true);
            formik.setFieldValue('firstNameOperation', formik.values.firstName)
            formik.setFieldValue('lastNameOperation', formik.values.lastName)
            formik.setFieldValue('emailOperation', formik.values.email)
            formik.setFieldValue('mobileNumberOperation', formik.values.mobileNumber)
            formik.setFieldValue('jobTitleOperation', formik.values.jobTitle)
        } else {
            setDisableFinance(true);
            formik.setFieldValue('firstNameOperation', "")
            formik.setFieldValue('lastNameOperation', "")
            formik.setFieldValue('emailOperation', "")
            formik.setFieldValue('mobileNumberOperation', "")
            formik.setFieldValue('jobTitleOperation', "")
        }
    }

    const handleFinance = (e) => {
        if (e.target.checked) {
            formik.setFieldValue('firstNameFinance', formik.values.firstName)
            formik.setFieldValue('lastNameFinance', formik.values.lastName)
            formik.setFieldValue('emailFinance', formik.values.email)
            formik.setFieldValue('mobileNumberFinance', formik.values.mobileNumber)
            formik.setFieldValue('jobTitleFinance', formik.values.jobTitle)
        } else {
            formik.setFieldValue('firstNameFinance', "")
            formik.setFieldValue('lastNameFinance', "")
            formik.setFieldValue('emailFinance', "")
            formik.setFieldValue('mobileNumberFinance', "")
            formik.setFieldValue('jobTitleFinance', "")
        }
    }

    return (
        <div>
            <div className="modalMask">
                <div className="userModalWrapper" style={{ borderRadius: "12px", width: "690px" }}>
                    <div className="modal__body scroller_modal">
                        <div>
                            <div className="usermodal__body modal__body" style={{ gap: "5px" }}>
                                <div className="flex justify-flex">
                                    <img src={modalImage1} alt="" />
                                    <button
                                        className="close-button"
                                        onClick={() => {
                                            setCreateCustomer(false);
                                            setIsEdit(false);
                                            setInitailCustomer(customerSchema);
                                        }}
                                    >
                                        <VscChromeClose />
                                    </button>
                                </div>
                                <div className="customer-details">
                                    {
                                        isEdit ? "Edit Admin Details!" : "Create New Admin!"
                                    }
                                </div>
                                <div className="please-enter-the-req">
                                    {
                                        isEdit ? "Please enter the required details correctly. Thanks!" : "Please enter the required details correctly. Thanks!"
                                    }
                                </div>
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    if (isEdit) {
                                        setCreateCustomer(false);
                                        setConfirmModal(true);
                                    } else {
                                        handleSubmit();
                                    }
                                }} action="" className='modal-form'>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Company
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.company
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Company"
                                                        name="company"
                                                        onChange={handleChange}
                                                        value={values.company}
                                                        defaultValue={values.company}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.company}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                VAT ID
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.vat
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <p style={{ margin: "0", paddingLeft: "5px", fontSize: "13px" }}>{countryCode}</p>
                                                    <input
                                                        placeholder="VAT ID"
                                                        name="vat"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.vat}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.vat}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Email
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.email
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Email Address"
                                                        name="email"
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        defaultValue={values.email}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Phone
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.mobileNumber
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Enter Phone #"
                                                        name="mobileNumber"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.mobileNumber}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.mobileNumber}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                First Name
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.firstName
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="First Name"
                                                        name="firstName"
                                                        onChange={handleChange}
                                                        value={values.firstName}
                                                        defaultValue={values.firstName}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.firstName}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Last Name
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.lastName
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Last Name"
                                                        name="lastName"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.lastName}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.lastName}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Country
                                            </label>
                                            <div className="input__with__error">
                                                <Select
                                                    options={countryList}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            maxHeight: '200px',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                    placeholder="country"
                                                    value={country}
                                                    onChange={value => {
                                                        setStart(false);
                                                        formik.setFieldValue('country', value.label)
                                                        setCountry(value);
                                                        setState("")
                                                        setCity("")
                                                        formik.setFieldValue('state', "")
                                                        formik.setFieldValue('city', "")
                                                    }}
                                                />
                                                <span>{formik.errors.country}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                State
                                            </label>
                                            <div className="input__with__error">
                                                <Select
                                                    options={stateList}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            maxHeight: '200px',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                    placeholder="state"
                                                    value={state}
                                                    isDisabled={!formik.values.country}
                                                    onChange={value => {
                                                        setStart(false);
                                                        formik.setFieldValue('state', value.label)
                                                        setState(value);
                                                        setCity("")
                                                        formik.setFieldValue('city', "")
                                                    }}
                                                />
                                                <span>{formik.errors.state}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                City
                                            </label>
                                            <div className="input__with__error">
                                                <Select
                                                    options={cityList}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            maxHeight: '200px',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                    placeholder="city"
                                                    value={city}
                                                    isDisabled={!formik.values.state || !formik.values.country}
                                                    onChange={(value) => {
                                                        setStart(false);
                                                        formik.setFieldValue('city', value.label)
                                                        setCity(value)
                                                    }}
                                                />
                                                <span>{formik.errors.city}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Address
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.address
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Address"
                                                        name="address"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.address}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.address}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Postal Code
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.postalCode
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Postal Code"
                                                        name="postalCode"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.postalCode}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.postalCode}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Job Title
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.jobTitle
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Job Title"
                                                        name="jobTitle"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.jobTitle}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.jobTitle}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Plan
                                            </label>
                                            <div className="input__with__error">
                                                <Select
                                                    options={plansList}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            maxHeight: '200px',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                    placeholder="Select Plan"
                                                    value={
                                                        isEdit && formik.values?.plan?.label
                                                        ? formik.values.plan
                                                        : isEdit && !formik.values?.plan?.label
                                                        ? defaultPlan
                                                        : formik.values.plan
                                                    }
                                                    onChange={value => {
                                                        formik.setFieldValue('plan', value)
                                                    }}
                                                />
                                                <span>{formik.errors.plan}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Duration
                                            </label>
                                            <div className="input__with__error">
                                                <Select
                                                    options={durationList}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            maxHeight: '200px',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                    placeholder="Select Duration"
                                                    value={formik.values.duration}
                                                    onChange={value => {
                                                        formik.setFieldValue('duration', value)
                                                    }}
                                                />
                                                <span>{formik.errors.duration}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                                        <h4>Contact for Operations:</h4>
                                        <input
                                            type="checkbox"
                                            onChange={handleOperations}
                                            className='modal_check'
                                        />
                                        <p style={{ margin: "0px" }}>Same as above</p>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                First Name
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.firstNameOperation
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="First Name"
                                                        name="firstNameOperation"
                                                        onChange={handleChange}
                                                        value={values.firstNameOperation}
                                                        defaultValue={values.firstNameOperation}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.firstNameOperation}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Last Name
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.lastNameOperation
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Last Name"
                                                        name="lastNameOperation"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.lastNameOperation}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.lastNameOperation}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Email
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.emailOperation
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Email Address"
                                                        name="emailOperation"
                                                        onChange={handleChange}
                                                        value={values.emailOperation}
                                                        defaultValue={values.emailOperation}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.emailOperation}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Phone
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.mobileNumberOperation
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Enter Phone #"
                                                        name="mobileNumberOperation"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.mobileNumberOperation}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.mobileNumberOperation}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Job Title
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.jobTitleOperation
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Job Title"
                                                        name="jobTitleOperation"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.jobTitleOperation}
                                                        type="text"
                                                        // disabled={disableOperation}
                                                    />
                                                </div>
                                                <span>{formik.errors.jobTitleOperation}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                                        <h4>Contact for Finance:</h4>
                                        <input
                                            type="checkbox"
                                            className='modal_check'
                                            onChange={handleFinance}
                                        />
                                        <p style={{ margin: "0px" }}>Same as above</p>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                First Name
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.firstNameFinance
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="First Name"
                                                        name="firstNameFinance"
                                                        onChange={handleChange}
                                                        value={values.firstNameFinance}
                                                        defaultValue={values.firstNameFinance}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.firstNameFinance}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Last Name
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.lastNameFinance
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Last Name"
                                                        name="lastNameFinance"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.lastNameFinance}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.lastNameFinance}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Email
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.emailFinance
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Email Address"
                                                        name="emailFinance"
                                                        onChange={handleChange}
                                                        value={values.emailFinance}
                                                        defaultValue={values.emailFinance}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.emailFinance}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Phone
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.mobileNumberFinance
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Enter Phone #"
                                                        name="mobileNumberFinance"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.mobileNumberFinance}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.mobileNumberFinance}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Job Title
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.jobTitleFinance
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Job Title"
                                                        name="jobTitleFinance"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.jobTitleFinance}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.jobTitleFinance}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-center p-t20">
                                        <button
                                            className="flex-button"
                                            type='submit'
                                        >
                                            {addCustomerLoading || editCustomerLoading ? <AiOutlineLoading className="loaderIcon" size={18} /> : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormModal