// ****** contractors get Api******

export const getContractorFunc = async (token) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/contractors`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
};

// ****** contractors create Api******
export const createContractorFunc = async (token, data, addToast) => {
  delete data._id;
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/contractor-register`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, { appearance: "error", autoDismiss: true });
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};

export const createContractorWithoutToken = async (data, addToast) => {
  delete data._id;
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/contractor-register-user`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, { appearance: "error", autoDismiss: true });
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};

// ****** contractors edit Api******
export const editContractorFunc = async (token, data, addToast) => {
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/profile/${data?._id}`,
    {
      method: "PATCH",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, { appearance: "error" , autoDismiss: true,});
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};
