import React, { useState } from "react";
import modalImage1 from "../../../images/modals/packagedetail.png";
import { VscChromeClose } from "react-icons/vsc";
import {AiOutlineLoading} from 'react-icons/ai';

const DriverSearchModal = ({
  searchDriverModal,
  setSearchDriverModal,
  setVat,
  vat,
  savePreviousValue,
  getContractor,
}) => {
  const [loading, setLoading] = useState();

  return (
    <>
      {searchDriverModal && (
        <div className="modalMask">
          <div
            style={{ width: "415px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div className="usermodal__body modal__body">
                <div className="flex justify-flex">
                  <img src={modalImage1} alt="" />
                  <button
                    className="close-button"
                    onClick={() => {
                      setSearchDriverModal(false);
                      setVat("");
                    }}
                  >
                    <VscChromeClose />
                  </button>
                </div>

                <div className="sssign-contractor">Assign Contractor</div>
                <div className="please-enter-the-req">
                  Please enter given VAT code correctly Thanks!
                </div>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  getContractor();
                  if (vat) setLoading(true);
                  setTimeout(() => {
                    if (vat) setLoading(false);
                  }, 1000);
                }}>
                  <div className="flex-grid-f">
                    <div>
                      <label htmlFor="" className="grid-label">
                        VAT code
                      </label>
                      <div className="assign_rate_with_logo input__with__error">
                        <input
                          type="text"
                          value={vat}
                          onChange={(e) => setVat(e.target.value)}
                          placeholder="Enter VAT code"
                        />
                      </div>
                    </div>
                  </div>

                  <br />
                  <div className="flex-grid-f">
                    <button
                      className="flex-button-f"
                      type="submit"
                    >
                      {loading ? <AiOutlineLoading className="loaderIcon" size={18} />  : "Search"}
                    </button>
                  </div>

                  <div
                    onClick={savePreviousValue}
                    className="message_contractor assign-to-unregister"
                  >
                    <div>Assign to unregistered contractor</div>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DriverSearchModal;
