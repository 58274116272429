import React from 'react'
import { AiFillCamera, AiOutlineEdit } from 'react-icons/ai'
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";
import userLoader from '../../../../../images/user.png'
import axios from 'axios';


const schema = {
  fullName: ""
}

export const validationSchema = yup.object().shape({
  fullName: yup.string().trim().required("*FullName is required"),
});

const FirstSection = ({ profile, refetch, profileLoader, setProfileLoader }) => {
  const [editFirstSection, setEditFirstSection] = useState(false);
  const [initialValues, setInitialValues] = useState(schema);
  const token = localStorage.getItem("token");
  const { addToast } = useToasts();

  const editProfileFunc = async (data) => {
    setProfileLoader(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/users/myProfile/`,
      {
        method: "PATCH",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );

    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        setProfileLoader(false);
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      addToast(resJson.error, { appearance: "error", autoDismiss: true, });
      setEditFirstSection(false);
      setProfileLoader(false);
      throw new Error(resJson.error.message);
    }

    await refetch();
    setEditFirstSection(false);
    setProfileLoader(false);
    setPreview(null);
    setImage(null);
    addToast("Updated Successfully", { appearance: "success", autoDismiss: true, });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,

    onSubmit: async(values) => {
      if(image !== null) {
        const path = await handleUpload();
        const formData = {
          ...values,
          profilePhoto: path,
        }
        editProfileFunc(formData);
      } else {
        editProfileFunc(values);
      }
    },
  });

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  // Function to preview the image
  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreview(reader.result);
      setImage(file);
    };
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("image", image);

    try {
      const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });
      return data?.path
    } catch (err) {
      console.error("Error uploading image:", err);
    }
  };

  return (
    <div className="profile__first__section tab__section" style={{ alignItems: editFirstSection ? "flex-end" : "center" }}>
      <div className='profile__first__section__left' style={{ gap: editFirstSection ? "30px" : "15px" }}>
        {
          editFirstSection ? (
            <>
              <div className="profile_avatar_wrapper" style={{ height: "100px", width: "100px", cursor: "pointer", position: "relative" }} onClick={handleImageClick}>
                <img src={preview || profile?.profilePhoto} alt="avatar" />
                <AiFillCamera color='#fff' size={22} className='cam'/>
              </div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <div className="profile-flex-2">
                <div className="flex-input-wrap">
                  <label htmlFor="" className="grid-label2">
                    User Name
                  </label>
                  <div
                    className={
                      formik.errors.city
                        ? "error-border assign_rate_with_logo input__with__error"
                        : "assign_rate_with_logo input__with__error"
                    }
                  >
                    <input
                      type="text"
                      placeholder="Enter User Name"
                      value={formik.values.fullName}
                      onChange={(e) => formik.setFieldValue("fullName", e.target.value)}
                    />
                    <span>{formik.errors.fullName}</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="profile_avatar_wrapper">
                <img src={profile?.profilePhoto || userLoader} alt="avatar"/>
              </div>
              <div className='profile_flex_col'>
                <h2>{profile?.fullName}</h2>
                <p>{profile?.userType}</p>
              </div>
            </>
          )
        }
      </div>
      <div className='profile__first__section__right'>
        {
          editFirstSection ? (
            <div className='profile__first__section__right'>
              <span
                className='edit__btn'
                onClick={() => formik.handleSubmit()}
              >
                Save
              </span>
              <span
                className='edit__btn'
                onClick={() => {
                  setEditFirstSection(!editFirstSection);
                  setPreview(null);
                  setImage(null);
                }}
              >
                Cancel
              </span>
            </div>
          ) : (
            <div
              className='edit__btn'
              onClick={() => {
                formik.setFieldValue('fullName', profile?.fullName)
                setEditFirstSection(!editFirstSection)
              }}
            >
              <span>Edit</span>
              <AiOutlineEdit size={18} />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default FirstSection