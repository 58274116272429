import React, {useEffect} from "react";
import {
  Button,
  Form,
  Spinner
} from "reactstrap";

import logo from "../../images/tracking/track_image.svg"
import "./styles/forgetpassword.css";
import LoginModel from "../../components/molecules/loginModel";
import "font-awesome/css/font-awesome.min.css";

import { useNavigate, Link } from "react-router-dom";

import { useForgetPasswordInfo } from "./useForgetPassword";
import {getVerifyToken} from "../../helpers/localStorage";


const ForgetPassword = (props) => {
  const {
    modelShow,
    validEye,
    validation,
    validationMsg,
    inputRef,
    passwordShown,
    emailFunc,
    passwordFunc,
    modelFunc,
    ForgetPassword,
    togglePassword,
    handleFoucs,
    loading,
    errorMsg
  } = useForgetPasswordInfo(props);

  const navigate = useNavigate();
  const token = getVerifyToken();

  useEffect(() => {
    if(token){
      navigate("/dashboard")
    }
  }) 

  return (
    <>
      <div fluid className='login_wrapper'>
        <div className="login_card">
          <div className="lglogin ">
            <img src={logo} alt="logo"/>
            <p>Go Trucking</p>  
          </div>
          <h4 className="sign-in">Forget Password</h4>
          <p className="welcome">Enter your email, you'll recive OTP for verification</p>
          <Form onSubmit={ForgetPassword} style={{width: "300px", margin: "0 auto"}}>
            <>
              <div className="inputs">
                <div className="input-box">
                  <p className="login-label">Email</p>
                  <input
                    id="example-date-input-email"
                    onChange={(e) => emailFunc(e)}
                    placeholder="Enter your email"
                    // className={`${validation}`}
                    type="email"
                    ref={inputRef}
                  />
                </div>
                
              </div>
              <p className={`${validationMsg}`}>
                {errorMsg}
              </p>
            </>
            
            <div className="btn_wrap">
              <Button disabled={loading} className="waves-effect waves-light lgbtn login__btn">
              {
                loading ? <Spinner color="light" size="sm" /> : "Send OTP"
              }
              </Button>
            </div>
            
          </Form>
          
          <div style={{textAlign:"center"}}>
    
              <div className="route-link">
              <Link to="/login">
               Go To Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-img"></div>
      <LoginModel show={modelShow} setModelShow={modelFunc} />
    </>
  );
};

export default ForgetPassword;
