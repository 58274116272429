// ****** customers get Api******
export const getCustomerFunc = async (token) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/customers`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
};

// ****** customers create Api******
export const createCustomerFunc = async (token, data, addToast) => {
try {
  delete data?._id;
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/customer-register`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, {
      appearance: "error",
      autoDismiss: true,
    });
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
} catch (err) {
  throw new Error(err);
}
};

export const createCustomerWithoutLogin = async (data, addToast) => {
  delete data._id;
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/customer-register-user`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, {
      appearance: "error",
      autoDismiss: true,
    });
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};

// ****** customers edit Api******
export const editCustomerFunc = async (token, data) => {
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/profile/${data?._id}`,
    {
      method: "PATCH",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};
