import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Col, Row } from "reactstrap";
import sideBtn from "../../images/icons/Hamburger.svg";
import Navbar from "react-bootstrap/Navbar";
import "./css/header.css";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import sidebarheader from "../../images/icons/sidebarheader.svg";

import Dropdown from "react-bootstrap/Dropdown";

import { getNameToken } from "../../helpers/localStorage";
import { RiMenu3Fill } from "react-icons/ri";
import {HiTruck} from 'react-icons/hi'
import { FaAngleDown, FaUser } from "react-icons/fa";
import { BsArrowDownCircle } from "react-icons/bs";
import {IoIosNotificationsOutline} from 'react-icons/io'

const Header = ({ navshow, setNavShow, setInactive, inactive, setExpand, onCollapse }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split("/")[1];
  const heading = splitLocation.charAt(0).toUpperCase() + splitLocation.slice(1)
  const pattern = pathname.includes("/detail");
  const backBtn = pathname.split("/detail")[0];

  const userName = getNameToken();

  const navigation = () => {
    if (backBtn === "/tour") {
      const nav = pathname.split("/detail").join("s");
      navigate(nav);
    } else if (backBtn === "/pass") {
      const nav = pathname.split("/detail").join("es");
      navigate(nav);
    }
  };

  const [notifications, setNotification] = useState();

  // useEffect(() => {
  //   onValue(ref(firebaseDB, `notifications/`), (snapshot) => {
  //     const data = snapshot.val();
  //     if(data !== null){
  //       const arrayOfObj = Object.entries(data).map((e) => ( e[1] ));
  //       console.log("arrayOfObj", arrayOfObj)
  //       setNotification(data);
  //       console.log("firebase data", data)
  //       // setDriverLocation([data.lat, data.lng]);
  //       // setDriverAvailable(true);
  //     }else{
  //       console.log("firebase data no", data)
  //     }
  //   });
  // }, []);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <Navbar
        style={{
          position: "fixed",
          zIndex: "7",
          top: "0px",
          left: "0px",
        }}
        className="dbNavBar"
      >
        <Container fluid>
          {pattern ? (
            <span>
              <AiOutlineLeft className="" onClick={() => navigation()} />
            </span>
          ) : (
            ""
          )}
          <div className={inactive ? "header__left" : "header__left__expanded"}>
            <div className={inactive ? "header__logo__inactive" : "logo"}>
              <div>
                <HiTruck className="flex truck_logo"/>
              </div>
              {
                !inactive && (
                  <h4>GO TRUCKING</h4>
                )
              }
            </div>
            <div className="menu_icon">
              <RiMenu3Fill 
                onClick={() => {
                  setInactive(!inactive)
                  if (!inactive) {
                    setExpand(false)
                  }
                  onCollapse(inactive);
                }}
              />
            </div>
          </div>

          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          <div className="dbNavIcons">
            <div>
                <IoIosNotificationsOutline className="notification_icon"/>
            </div>
            <div className="name_section">
              <span>Hello,</span>
              <Dropdown dropleft="true">
                <Dropdown.Toggle
                  className="mx-1 header-dropdown btn-primary custom-toggle"
                  variant="secondary"
                  id="dropdown-basic"
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    margin: "0",
                    padding: "0"
                  }}
                >
                  {userName}
                  <BsArrowDownCircle style={{ marginLeft: '8px', color: "#354FAA", width: "20px", height: "20px" }} /> {/* Add the arrow icon */}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/profile")}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
