import { useFormik } from 'formik';
import React, { useState } from 'react'
import { AiOutlineEdit, AiOutlineLoading } from 'react-icons/ai';
import { useToasts } from 'react-toast-notifications';
import * as yup from "yup";
import "font-awesome/css/font-awesome.min.css";


const validationSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .trim()
    .required("*Current Password is required"),
  
  newPassword: yup
    .string()
    .trim()
    .required("*New Password is required")
    .min(8, "*must be at least 8 characters")
    .max(50, "*can't be longer than 50 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "*at least one uppercase letter, one number, and one special character"
    ),

  confirmPassword: yup
    .string()
    .trim()
    .required("*Re-Password is required")
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .min(8, "*must be at least 8 characters")
    .max(50, "*can't be longer than 50 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "*at least one uppercase letter, one number, and one special character"
    ),
});

const passwordSchema = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
}

const SecurityTab = () => {

  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(false);
  const [currentPasswordShown, setCurrentPasswordShown] = useState("");
  const [newPasswordShown, setNewPasswordShown] = useState("");
  const [rePasswordShown, setRePasswordShown] = useState("");


  const [initialValues, setInitialValues] = useState(passwordSchema);
  const { addToast } = useToasts();
  const [validEye, setValidEye] = useState("eye1");

  const changePassword = async (data) => {
    setLoader(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/users/changePassword/`,
      {
        method: "PATCH",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        setLoader(false);
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      addToast(resJson.error, { appearance: "error" , autoDismiss: true,});
      setLoader(false);
      throw new Error(resJson.error.message);
    }
    setLoader(false);
    formik.resetForm();
    addToast("Updated Successfully", { appearance: "success" , autoDismiss: true,});
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      changePassword(values);
    },
  });

  const togglePassword = (type) => {
    if(type === "currentPasswordShown") {
      setCurrentPasswordShown(!currentPasswordShown);
    } else if(type === "newPasswordShown") {
      setNewPasswordShown(!newPasswordShown)
    } else {
      setRePasswordShown(!rePasswordShown)
    }
  }

  return (
    <div className="profile__first__section tab__section" style={{alignItems:"flex-end"}}>
      {(loader) && (
          <div className='loader__overlay'>
            <AiOutlineLoading style={{ margin: "auto", textAlign: "center" }} className="loaderIcon" size={50} />
          </div>
      )}
        <div className='profile__second__section__left' style={{gap:"10px", width:"100%"}}>
          <p className='info'>Change Password</p>
            <div className='personal__second__edit'>
              <div className="personal-grid2">
                <div>
                  <label htmlFor="" className="grid-label">
                    Current Password
                  </label>
                  <div className="input__with__error">
                    <div className="assign_rate_with_logo">
                      <input
                        placeholder="Enter current password"
                        onChange={(e) => formik.setFieldValue('oldPassword', e.target.value)}
                        value={formik.values?.oldPassword}
                        type={currentPasswordShown ? "text" : "password"}
                      />
                      <i
                        onClick={() => togglePassword("currentPasswordShown")}
                        className={`fa ${validEye} ${
                          !currentPasswordShown ? "fa-eye-slash" : "fa-eye"
                        }`}
                      ></i>
                    </div>
                    <span>{formik?.errors?.oldPassword}</span>
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="grid-label">
                    New Password
                  </label>
                  <div className="input__with__error">
                    <div className="assign_rate_with_logo">
                      <input
                        placeholder="Enter new password"
                        onChange={(e) => formik.setFieldValue('newPassword', e.target.value)}
                        value={formik.values?.newPassword}
                        defaultValue={formik.values?.newPassword}
                        type={newPasswordShown ? "text" : "password"}
                      />
                      <i
                        onClick={() => togglePassword("newPasswordShown")}
                        className={`fa ${validEye} ${
                          !newPasswordShown ? "fa-eye-slash" : "fa-eye"
                        }`}
                      ></i>
                    </div>
                    <span>{formik.errors.newPassword}</span>
                  </div>
                </div>  
              </div>

              <div className="personal-grid2">
                <div>
                  <label htmlFor="" className="grid-label">
                    Re-password
                  </label>
                  <div className="input__with__error">
                    <div className="assign_rate_with_logo">
                      <input
                        placeholder="Re Password"
                        type={rePasswordShown ? "text" : "password"}
                        name="invoiceAddress"
                        onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value)}
                        value={formik.values?.confirmPassword}
                        defaultValue={formik.values?.confirmPassword}
                      />
                       <i
                        onClick={() => togglePassword("rePasswordShown")}
                        className={`fa ${validEye} ${
                          !rePasswordShown ? "fa-eye-slash" : "fa-eye"
                        }`}
                      ></i>
                    </div>
                    <span>{formik.errors.confirmPassword}</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className='profile__first__section__right'>
          <div className='profile__first__section__right'>
            <span className='edit__btn' onClick={() => formik.handleSubmit()}>Save</span>
          </div>
        </div>
    </div>
  )
}

export default SecurityTab