import React, { useState, useEffect } from "react";
import { GoogleMap, Autocomplete, MarkerF, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 120px)",
};

const Map = ({
  visible,
  setVisible,
  userAddress,
  setUserAddress,
  name,
  setArea,
}) => {
  const [markerPosition, setMarkerPosition] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: markerPosition }, (results, status) => {
        if (status === "OK") {
          setAddress(results[0].formatted_address);
          extractAddressComponents(results[0]);
        }
      });
    }
  }, [markerPosition, isLoaded]);

  const extractAddressComponents = (result) => {
    const components = result.address_components;
    let city = "";
    let state = "";
    let postcode = "";
    let country = "";

    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      const types = component.types;

      if (types.includes("locality")) {
        city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (types.includes("postal_code")) {
        postcode = component.long_name;
      } else if (types.includes("country")) {
        country = component.long_name;
      }
    }

    setCity(city);
    setState(state);
    setPostcode(postcode);
    setCountry(country);
  };

  const handleMarkerDragend = (e) => {
    const { latLng } = e;
    setMarkerPosition({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
  };

  const onBack = () => {
    setVisible(!visible);
  };

  const onSave = () => {
    let coords = Object.values(markerPosition).map((coord) => coord);
    
    setUserAddress({
      ...userAddress,
      address: address,
      latlng: [coords[0], coords[1]],
      city: city,
      state: state,
      country: country,
      postCode: postcode,
    });

    setVisible(!visible);
  };

  const autocompleteRef = React.useRef(null);

  const onAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const location = place.geometry.location;
      setMarkerPosition({
        lat: location.lat(),
        lng: location.lng(),
      });
      setCenter({
        lat: location.lat(),
        lng: location.lng(),
      });
      
    }
  };
  
  return isLoaded ? (
    <div className="maps__wrapper">
      <Autocomplete
        onLoad={onAutocompleteLoad}
        onPlaceChanged={onPlaceChanged}
      >
        <input
          type="text"
          placeholder="Search Address"
          className="autocomplete"
        />
      </Autocomplete>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
        <MarkerF
          position={markerPosition}
          draggable={true}
          onDragEnd={(e) => handleMarkerDragend(e)}
        />
        {address && <p>Address: {address}</p>}
      </GoogleMap>
      <button className="maps__back__btn" onClick={onBack}>
        Back
      </button>
      <button className="maps__save__btn" onClick={onSave}>
        Save
      </button>
    </div>
  ) : (
    <></>
  );
};

export default Map;
