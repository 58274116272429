import React from 'react'
import './css/profile.css'

const Menu = ({activeTab, setActiveTab}) => {
  return (
    <div className='profile__menu'>
        <h4 
        onClick={() => setActiveTab("My Profile")}
        className={activeTab === "My Profile" ? "profile__active__menu__item profile__menu__item" : "profile__menu__item"}
        >
        My Profile
        </h4>
        <h4
        onClick={() => setActiveTab("Security")}
        className={activeTab === "Security" ? "profile__active__menu__item profile__menu__item" : "profile__menu__item"}
        >
        Security
        </h4>
        {/* <h4
        onClick={() => setActiveTab("Data Export")}
        className={activeTab === "Data Export" ? "profile__active__menu__item profile__menu__item" : "profile__menu__item"}
        >
        Data Export
        </h4>
        <h4
        onClick={() => setActiveTab("Delete Account")}
        className={activeTab === "Delete Account" ? "profile__active__menu__item profile__menu__item" : "profile__menu__item"}
        >
        Delete Account
        </h4> */}
    </div>
  )
}

export default Menu