export const findContractor = async (token, id) => {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/users/contractor/${id}`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    const data = await res.json();
    return {
      data,
    };
  };