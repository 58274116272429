import React from "react";
import error_image from "../../../images/modals/error-image.png";
import { VscChromeClose } from "react-icons/vsc";
import { Link } from "react-router-dom";

const ErrorModal = ({
  searchError,
  setSearchError,
  setVat,
  sendInvite,
  setSearchDriverModal,
}) => {
  return (
    <>
      {searchError && (
        <div className="modalMask">
          <div
            style={{ width: "512px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <button
                    className="close-button absolute-btn"
                    onClick={() => {
                      setSearchError(false);
                      setVat("");
                    }}
                  >
                    <VscChromeClose />
                  </button>
                  <div className="flex justify-center">
                    <img src={error_image} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <div className="no-customer-was-foun">
                      No customer was found with provided name.
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="">
                      <button
                        className="invite_btn"
                        onClick={() => {
                          sendInvite();
                          setVat("");
                        }}
                      >
                        Send them invite via link
                      </button>
                      <br />
                      <Link to="/contractor?create=true">
                        <button
                          className="error_create_new"
                          onClick={() => {
                            setVat("");
                            setSearchError(false);
                          }}
                        >
                          Create New
                        </button>
                      </Link>
                      <br />
                      <button
                        className="invite_btn"
                        onClick={() => {
                          setSearchError(false);
                          setVat("");
                          setSearchDriverModal(true);
                        }}
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorModal;
