/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import customer_breadcrumb from '../../images/icons/customer_breadcrumb.png'

import { useQuery, useQueryClient, useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import "./css/customer.css";

import Table from "../../components/tables/Table";
import { RiSearch2Line } from 'react-icons/ri'
import * as yup from 'yup';
import delete_confirm from "../../images/modals/delete.png"

import { VscChromeClose } from "react-icons/vsc";
import modalImage1 from "../../images/modals/typewriter.svg";
import { getPlansFunc } from "../../ApIs/plans";
import { useFormik } from "formik";
import {AiOutlineLoading} from 'react-icons/ai'

const planSchema = {
  name: "",
  price: "",
  orderLimit: "",
  isPDFAllow: "",
};

const planValidation = yup.object().shape({
  name: yup.mixed().required('*required'),
  price: yup.mixed().required('*required'),
  orderLimit: yup.mixed().required('*required'),
  isPDFAllow: yup.mixed().required('*required'),
});

const Plans = () => {
  const [search, setSearch] = useState("");
  const [filterTable, setFilterTable] = useState([]);
  const [modal_static8, setModal_static8] = useState(false);
  const [searchError, setSearchError] = React.useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [planId, setPlanId] = useState("");
  const token = localStorage.getItem("token");
  const [createPlan, setCreatePlan] = useState(false);
  const [planDetails, setPlanDetails] = useState(planSchema);
  const [loader, setLoader] = useState(false);

  const getAllplans = () => getPlansFunc(token);

  const { data: allPlans, refetch, isLoading } = useQuery(["plans"], () =>
    getAllplans()
  );

  const makePlan = async (values) => {
    setLoader(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/plans/create`,
        {
          method: "POST",
          redirect: "follow",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            ...values,
            isPDFAllow: values.isPDFAllow === 'true' ? true : false,
          }),
        }
      );
    
      if (!res.ok) {
        if (res.status === 401) {
          const resJson = await res.json();
          throw new Error(resJson.error.message);
        }
        const resJson = await res.json();
        addToast(resJson.error, { appearance: "error" , autoDismiss: true,});
        throw new Error(resJson.error.message);
      }
    
      const responseData = await res.json();
      if(responseData.success) {
        refetch();
        resetForm();
        setCreatePlan(false);
        addToast(responseData?.message, { appearance: "success" , autoDismiss: true,});
      }
    } catch(err) {
      addToast(err?.response?.data?.message, { appearance: "error" , autoDismiss: true,});
    } finally {
      setLoader(false);
    }
  }

  const updatePlan = async (values) => {
    setLoader(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/plans/update`,
        {
          method: "PATCH",
          redirect: "follow",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            ...values,
            id: isEdit || "",
            isPDFAllow: values.isPDFAllow === 'true' ? true : false,
          }),
        }
      );
    
      if (!res.ok) {
        if (res.status === 401) {
          const resJson = await res.json();
          throw new Error(resJson.error.message);
        }
        const resJson = await res.json();
        addToast(resJson.error, { appearance: "error" , autoDismiss: true,});
        throw new Error(resJson.error.message);
      }
    
      const responseData = await res.json();
      if(responseData.success) {
        refetch();
        resetForm();
        setCreatePlan(false);
        setIsEdit(null);
        addToast(responseData?.message, { appearance: "success" , autoDismiss: true,});
      }
    } catch(err) {
      console.log("errr", err)
      // addToast(err?.response?.data?.message, { appearance: "error" , autoDismiss: true,});
    } finally {
      setLoader(false);
    }
  }

  const {
    values,
    errors,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: planDetails,
    validationSchema: planValidation,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      isEdit ? updatePlan(values) : makePlan(values);
    }
  });


  const [hoveredRowId, setHoveredRowId] = useState(null);

  const columns = [
    {
      name: "Sr#",
      selector: (row, index) => index + 1,
      width: "50px",
    },

    {
      name: "Name",
      selector: (row) => {
        return (
          <div>
            <span>{row.name}</span>
            {hoveredRowId === row._id && (
              <div className="actions__below">
                <span
                  onClick={() => {
                    setCreatePlan(true);
                    setIsEdit(row?._id)
                    setFieldValue('name', row?.name)
                    setFieldValue('price', row?.price)
                    setFieldValue('orderLimit', row?.orderLimit)
                    setFieldValue('isPDFAllow', row?.isPDFAllow)
                  }}
                  className="delete_action"
                >
                  Edit
                </span>
                <span
                  onClick={() => {
                    setModal_static8(true);
                    setPlanId(row?._id);
                  }}
                  className="delete_action"
                >
                  Delete
                </span>
              </div>
            )}
          </div>
        )
      },
      width: "150px",
    },
    {
      name: "Order Limit",
      width: "150px",
      selector: (row) => row?.orderLimit,
    },
    {
      name: "PDF Allow",
      width: "150px",
      selector: (row) => row?.isPDFAllow === true ? "Yes" : "No"
    },
    {
      name: "Price",
      width: "150px",
      selector: (row) => row?.price + "   " + "EURO"
    }
  ];

  const allPlansData = allPlans?.data?.data;

  useEffect(() => {
    queryClient.invalidateQueries("AllOrders");
  }, []);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const [toggledClearRows] = React.useState(false);

  const DelPlanFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/plans/delete`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ "id": planId }),
    })
      .then((result) => {
        console.log("result", result)
        if (result.status === 200) {
          refetch();
          setModal_static8(false);
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (result.status === 204) {
          setModal_static8(false);
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          refetch();
          queryClient.invalidateQueries("allPlans");
        } else {
          setModal_static8(false);
          addToast("This Plan cannot be deleted", {
            appearance: "error",
            autoDismiss: true,
          });
          queryClient.invalidateQueries("allPlans");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  useEffect(() => {
    const result = allPlansData?.filter((e) => {
      return (
        e?.name?.toLowerCase().match(search.toLowerCase()) ||
        e?.orderLimit?.toLowerCase().match(search.toLowerCase()) ||
        e?.price.match(search.toLowerCase())
      );
    });
    setFilterTable(result);
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    const result = allPlansData?.filter((e) => {
      return (
        e?.name?.toLowerCase().match(search.toLowerCase()) ||
        e?.orderLimit?.toLowerCase().match(search.toLowerCase()) ||
        e?.price.match(search.toLowerCase())
      );
    });

    if (result.length < 1) {
      setSearchError(true);
    } else {
      setFilterTable(result);
    }
  };

  const actionOptions = [{ value: "delete", label: "Delete Selected" }];

  const customStyles = {
    table: {
      borderRadius: "20px",
      style: {
        height: `${filterTable ? filterTable?.length === 1 ? "200px" : "auto" : allPlansData?.length === 1 ? "200px" : "auto"
          }`
      }

    },
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  let debounceTimeout;
  const debounce = (func, key, delay) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      func(key);
    }, delay);
  };

  const onDelaySearch = (search) => {
    const result = allPlansData?.filter((e) => {
      return (
        e?.name?.toLowerCase().match(search.toLowerCase()) ||
        e?.orderLimit?.toLowerCase().match(search.toLowerCase()) ||
        e?.price.match(search.toLowerCase())
      );
    });

    if (result.length < 1) {
      setSearchError(true);
    } else {
      setFilterTable(result);
    }
  }

  const handleInputChange = (e) => {
    setSearch(e.target.value)
    const search = () => onDelaySearch(e.target.value);
    debounce(search, 1000);
  };

  return (
    <div>
      {modal_static8 && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img src={delete_confirm} alt="" style={{ width: "200px", height: "183px" }} />
              <p>Are you sure?</p>
              <p>
                You cannot able to delete if any active user have this plan.
              </p>
              <div className="edit__btns">
                <button className="edit_no" onClick={() => setModal_static8(false)}>
                  No
                </button>
                <button
                  className={`edit_yes`}
                  onClick={DelPlanFunc}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {
        createPlan && (
          <div className="modalMask">
            <div className="userModalWrapper" style={{ borderRadius: "12px", width: "690px" }}>
              <div className="modal__body scroller_modal">
                <div>
                  <div className="usermodal__body modal__body" style={{ gap: "5px" }}>
                    <div className="flex justify-flex">
                      <img src={modalImage1} alt="" />
                      <button
                        className="close-button"
                        onClick={() => {
                          setCreatePlan(false);
                          setIsEdit(null);
                          resetForm();
                        }}
                      >
                        <VscChromeClose />
                      </button>
                    </div>
                    <div className="customer-details">
                      {
                        isEdit ? "Edit Plan" : "Create Plans"
                      }
                    </div>
                    <div className="please-enter-the-req">
                      Please enter the required details correctly. Thanks!
                    </div>
                    <div className='modal-form'>
                      <div className="grid-flex2">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Plan Name
                          </label>
                          <div className="input__with__error">
                            <div
                              className={
                                errors.name
                                  ? "error_border_input"
                                  : "assign_rate_with_logo"
                              }
                            >
                              <input
                                placeholder="Plan Name"
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                                type="text"
                              />
                            </div>
                            <span>{errors.name}</span>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="" className="grid-label">
                            Plan Price
                          </label>
                          <div className="input__with__error">
                            <div
                              className={
                                errors.price
                                  ? "error_border_input"
                                  : "assign_rate_with_logo"
                              }
                            >
                              <input
                                placeholder="Plan Price"
                                name="price"
                                onChange={handleChange}
                                value={values.price}
                                type="number"
                              />
                            </div>
                            <span>{errors.price}</span>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="" className="grid-label">
                            Orders Limit
                          </label>
                          <div className="input__with__error">
                            <div
                              className={
                                errors.orderLimit
                                  ? "error_border_input"
                                  : "assign_rate_with_logo"
                              }
                            >
                              <input
                                placeholder="Orders Limit"
                                name="orderLimit"
                                onChange={handleChange}
                                value={values.orderLimit}
                                type="number"
                              />
                            </div>
                            <span>{errors.orderLimit}</span>
                          </div>
                        </div>
                      </div>
                      <div className="grid-flex2">
                        <div>
                          <label htmlFor="" className="grid-label">
                            PDF Allow
                          </label>
                          <div className="input__with__error" style={{ display: "flex", gap: "10px" }}>
                            <div>
                              <label style={{ display: "flex", gap: "5px" }}>
                                <input
                                  type="radio"
                                  name="isPDFAllow"
                                  value='true'
                                  onChange={(e) => {
                                    setFieldValue('isPDFAllow', e.target.value)
                                  }}
                                  checked={values.isPDFAllow === 'true' || values.isPDFAllow === true}
                                />
                                Yes
                              </label>
                            </div>
                            <div>
                              <label style={{ display: "flex", gap: "5px" }}>
                                <input
                                  type="radio"
                                  name="isPDFAllow"
                                  value={'false'}
                                  onChange={(e) => {
                                    setFieldValue('isPDFAllow', e.target.value)
                                  }}
                                  checked={values.isPDFAllow === false || values.isPDFAllow === 'false'}
                                />
                                No
                              </label>
                            </div>
                          </div>
                          <span style={{color: "red", fontSize: '10px'}}>{errors.isPDFAllow}</span>
                        </div>

                      </div>

                      <div className="flex-center p-t20" style={{ gap: "20px" }}>
                        <button
                          className="flex-button"
                          onClick={handleSubmit}
                          disabled={loader}
                          style={{cursor: loader ? "not-allowed" : "pointer"}}
                        >
                          {
                            loader 
                            ? <AiOutlineLoading className="loaderIcon" size={18} /> 
                            : isEdit
                            ? "Edit"
                            : "Submit"
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }




      <div className="breadcrumb">
        <img src={customer_breadcrumb} alt="truck-logo" />
        <span>/</span>
        <p>Plans</p>
      </div>
      <div className="boder-table-com">
        <div>
          <Table
            title=""
            customStyles={customStyles}
            columns={columns}
            progressPending={isLoading}
            data={filterTable ? filterTable : allPlansData}
            pagination={true}
            fixedHeader={false}
            onRowHover={(id) => setHoveredRowId(id)}
            fixedHeaderScrollHeight="1000px"
            selectableRows={false}
            onSelectedRowsChange={handleChange}
            selectCheckboxProps={{
              checked: (rowData) => selectedRows.includes(rowData?._id),
              onClick: (e, rowData) => {
                const rowId = rowData?._id;
                if (selectedRows.includes(rowId)) {
                  setSelectedRows(selectedRows.filter((id) => id !== rowId));
                } else {
                  setSelectedRows([...selectedRows, rowId]);
                }
              },
            }}
            clearSelectedRows={toggledClearRows}
            selectableRowsHighlight={true}
            highlightOnHover={true}
            subHeader={true}
            subHeaderComponent={
              <>
                <div className="table__header">
                  <div className="table__left">
                    {/* <button
                    // onClick={handleSelectAllRows}
                    className="select__btn"
                  >
                    Select All
                  </button> */}

                  </div>
                  <div className="table__right">
                    <form onSubmit={(e) => onSearch(e)} action="">

                      <div className="table__Search">
                        <div className="input_search">
                          <RiSearch2Line onClick={(e) => onSearch(e)} style={{ color: "#bebebe", width: "18px", height: "18px", cursor: "pointer" }} />
                        </div>
                        <input
                          type="text"
                          placeholder="Search by anything"
                          value={search}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                    </form>

                    <button
                      onClick={() => setCreatePlan(true)}
                      className="create__btn"
                    >
                      Create New
                    </button>

                  </div>
                </div>
              </>
            }

          />
        </div>
      </div>
    </div>
  );
};

export default Plans;
