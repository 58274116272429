import * as yup from "yup";

export const validationSchema = yup.object().shape({
  address: yup.string().trim().required("*Address is required"),
  country: yup.string().trim().required("*Country is required"),
  state: yup.string().trim().required("*State is required"),
  city: yup.string().trim().required("*City is required"),
  postcode: yup.string(),
  lat: yup.number(),
  lng: yup.number(),
});

export const addressSchema = {
  address: "",
  country: "",
  state: "",
  city: "",
  postcode: "",
  lat: "",
  lng: "",
};
