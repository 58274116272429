import React from 'react';
import ReactECharts from 'echarts-for-react';

function DoughnutChart({ data }) {

  const data1 = [
    {
        "name": "Plan 2",
        "value": 1
    },
    {
        "name": "Plan 1",
        "value": 1
    },
    {
        "name": "Plan 3",
        "value": 1
    }
]

  // Create the option for the ECharts chart
  const option = {
    title: {
      text: 'Active Plans',
      x: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',
    },
    series: [
      {
        name: 'Data',
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'],
        data: data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };



  return (
    <div>
      <ReactECharts
        option={option}
        style={{ height: '200px', backgroundColor: "white", borderRadius: "30px" }}
      />
    </div>
  );
}

export default DoughnutChart;
