import * as yup from "yup";

export const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("*First Name is required"),
  lastName: yup.string().trim().required("*Last Name is required"),
  email: yup.string().trim().email("*enter valid email").required("*email is required"),
  mobileNumber: yup.string().trim().required("*phone is required"),
  vat: yup.string().required("*vat is required"),
  company: yup.string().trim().required("*company is required"),
  jobTitle: yup.string().trim().required("*job title is required"),
  state: yup.string().trim(),
  city: yup.string().trim(),
  country: yup.string().trim(),
  postalCode: yup.string().required("*Postal Code  is required"),
  address: yup.string().trim().required("*address is required"),
  plan: yup.mixed().required("*plan is required"),
  duration: yup.mixed().required("*duration is required"),
  firstNameOperation: yup.string().trim().required("*First Name is required"),
  lastNameOperation: yup.string().trim().required("*Last Name is required"),
  emailOperation: yup.string().trim().email("*enter valid email").required("*email is required"),
  mobileNumberOperation: yup.string().trim().required("*phone is required"),
  jobTitleOperation: yup.string().trim().required("*job title is required"),

  firstNameFinance: yup.string().trim().required("*First Name is required"),
  lastNameFinance: yup.string().trim().required("*Last Name is required"),
  emailFinance: yup.string().trim().email("*enter valid email").required("*email is required"),
  mobileNumberFinance: yup.string().trim().required("*phone is required"),
  jobTitleFinance: yup.string().trim().required("*job title is required"),
});

export const shippingCustomerValidation = yup.object().shape({
  firstName: yup.string().trim(),
  lastName: yup.string().trim(),
  name: yup.string().trim().required("*full name is required"),
  email: yup.string().trim().email("*enter valid email").required("*email is required"),
  mobileNumber: yup.string().trim().required("*phone is required"),
  vat: yup.string().required("*vat is required"),
  company: yup.string().trim(),
  jobTitle: yup.string().trim(),
  state: yup.string().trim(),
  city: yup.string().trim(),
  country: yup.string().trim(),
  postalCode: yup.string().required("*Postal Code  is required"),
  address: yup.string().trim().required("*address is required"),
  invoiceAddress: yup.string().trim().required("*address is required"),
});

export const customerSchema = {
  _id: "",
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  vat: "",
  company: "",
  jobTitle: "",
  state: "",
  city: "",
  country: "",
  postalCode: "",
  address: "",
  firstNameOperation: "",
  lastNameOperation: "",
  emailOperation: "",
  mobileNumberOperation: "",
  jobTitleOperation: "",
  firstNameFinance: "",
  lastNameFinance: "",
  emailFinance: "",
  mobileNumberFinance: "",
  jobTitleFinance: "",
  plan: "",
  duration: ""
};

export const shippingCustomerSchema = {
  _id: "",
  name: "",
  email: "",
  mobileNumber: "",
  vat: "",
  postalCode: "",
  address: "",
  invoiceAddress: "",
};
