import React from "react";
import { VscChromeClose } from "react-icons/vsc";
import sent_email from "../../../images/modals/sent-email.png";

const InvitationModal = ({
  invitationModal,
  setInvitationModal,
  setVat,
  inviteReset,
  inviteErrors,
  handleChange,
  handleBlur,
  inviteValues,
  inviteSubmit,
  loading,
}) => {
  return (
    <>
      {invitationModal && (
        <div className="modalMask">
          <div
            style={{ width: "512px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <button
                    className="close-button absolute-btn"
                    onClick={() => {
                      setInvitationModal(false);
                      setVat("");
                      inviteReset();
                    }}
                  >
                    <VscChromeClose />
                  </button>
                  <div className="flex justify-center">
                    <img src={sent_email} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <div className="no-customer-was-foun sent_invite">
                      Please enter the required details correctly. Thanks!
                    </div>
                  </div>
                  <form onSubmit={(e) => {
                    e.preventDefault()
                    inviteSubmit()
                  }} action="">
                    <div className="flex justify-center">

                      <div className="invite__inputs">
                        <div className="input__with__error flex justify-center">
                          <div>
                            <label htmlFor="" className="grid-label">
                              Full Name
                            </label>
                            <div
                              className={
                                inviteErrors.name
                                  ? "error_border_input invite_input_field"
                                  : "assign_rate_with_logo0 invite_input_field"
                              }
                            >
                              <input
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={inviteValues.name}
                                placeholder="Enter Full Name"
                                type="text"
                              />
                              <span className="margin-left-80">
                                {inviteErrors.name}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="input__with__error flex justify-center">
                          <div>
                            <label htmlFor="" className="grid-label">
                              Email Address
                            </label>
                            <div
                              className={
                                inviteErrors.email
                                  ? "error_border_input invite_input_field"
                                  : "assign_rate_with_logo0 invite_input_field"
                              }
                            >
                              <input
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Email"
                                value={inviteValues.email}
                                type="text"
                              />
                              <span className="margin-left-80">
                                {inviteErrors.email}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <button
                            className="error_create_new error_create_new"
                            type="submit"
                            style={loading ? { cursor: "no-drop" } : {}}
                          >
                            {loading ? "Sending ..." : "Send An Email"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvitationModal;
