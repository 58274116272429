import * as yup from "yup";

export const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("*First Name is required"),
  lastName: yup.string().trim().required("*Last Name is required"),
  email: yup.string().trim().email("*enter valid email").required("*email is required"),
  mobileNumber: yup.string().trim().required("*mobileNumber is required"),
});

export const personalInfoSchema = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
};
