// ****** Admins get Api******
export const getAdminsFunc = async (token) => {
    // return await (
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/users/admins`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
  
    return {
      data,
    };
};

export const getPlansFunc = async (token) => {
    // return await (
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/plans/get-all`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
  
    return {
      data,
    };
};
  
// ****** Admins create Api******
export const createAdminFunc = async (token, data, addToast) => {
try {
delete data?._id;
const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/admin-register`,
    {
    method: "POST",
    redirect: "follow",
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
    }
);

if (!res.ok) {
    if (res.status === 401) {
    const resJson = await res.json();
    throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, {
    appearance: "error",
    autoDismiss: true,
    });
    throw new Error(resJson.error.message);
}

const responseData = await res.json();

return {
    data: responseData,
};
} catch (err) {
throw new Error(err);
}
};
  
// ****** customers edit Api******
export const editAdminFunc = async (token, data) => {
    const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/users/profile/${data?._id}`,
        {
        method: "PATCH",
        redirect: "follow",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
        }
    );

    if (!res.ok) {
        if (res.status === 401) {
        const resJson = await res.json();
        throw new Error(resJson.error.message);
        }
        const resJson = await res.json();
        throw new Error(resJson.error.message);
    }

    const responseData = await res.json();

    return {
        data: responseData,
    };
};


// ****** Admins get Api******
export const getRecentCustomersFunc = async (token) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/recent-customers`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
};


// ****** Admins Stats get Api******
export const getStats = async (token) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/plan-counts`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
};
  