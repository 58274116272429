import { useMutation } from "react-query";
import { LOGIN_MUTATION, FORGET_PASSWORD_MUTATION, SET_PASSWORD_MUTATION } from "./keys";

import authApi from "../services/loginApi";
import ChangePasswordApi from "../services/ChangePasswordApi";
import ResetPasswordApi from "../services/ResetPasswordApi";

export const useLoginMutation = (history) => {
  const response = useMutation(authApi.login, {
    mutationKey: LOGIN_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {
      
    },
  });
  return response;
};

export const useForgetPasswordMutation = (history) => {
  const response = useMutation(ChangePasswordApi.forgetPassword, {
    mutationKey: FORGET_PASSWORD_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useResetPasswordMutation = (history) => {
  const response = useMutation(ResetPasswordApi.ResetPassword, {
    mutationKey: SET_PASSWORD_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
