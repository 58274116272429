import React, { useState } from 'react'
import {AiOutlineDashboard} from 'react-icons/ai'
import './css/dashboard.css'
import { useEffect } from 'react'
import { useToasts } from "react-toast-notifications";


import { useQuery} from "react-query";
import moment from "moment";

import Table from "../../components/tables/Table";
import { getRecentCustomersFunc, getStats } from "../../ApIs/admins";

import DoughnutChart from './doughnutChart';
import MonthlyDataChart from "./chart";
import UserIcon from "../../images/icons/customers-active.svg";

const Dashboard = () => {
  const token = localStorage.getItem("token");
  const { addToast } = useToasts();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);

  const { data: recentCustomers, refetch, isLoading } = useQuery(["customers"], () =>
  fetchAllCustomers()
  );

  const fetchAllCustomers = () => getRecentCustomersFunc(token);

  const { data: adminStats } = useQuery(["stats"], () =>
  fetchAdminstats()
  );

  const fetchAdminstats = () => getStats(token);


  // *************
  const RecentCustomersData = recentCustomers?.data?.customers;

  const AllAdminStatusData = adminStats?.data
  // *************

  console.log("AllAdminStatusData", AllAdminStatusData)

  const columns = [
    {
      name: "Signup Date",
      selector: (row, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{row?.createdAt?.split("T")[0]}</span>
            <span>{row?.createdAt?.split("T")[1]?.split(".")[0]}</span>
            <span>{moment(row?.createdAt).fromNow()}</span>
          </div>
        );
      },
    },
    {
      name: "First Name",
      selector: (row) => {
        return (
          <div>
            <span>{row.firstName}</span>
          </div>
        )
      }
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Email",
      width: "150px",
      selector: (row) => row.email,
    },
    {
      name: "Plan",
      width: "100px",
      selector: (row) => row?.plan?.name || row?.plan?.id,
    },
    {
      name: "Duration",
      width: "100px",
      selector: (row) => row.plan?.duration?.toUpperCase(),
    },


    {
      name: "Company",
      selector: (row) => row?.company,
    },



  ];

  const customStyles = {
    table: {
      borderRadius: "20px",
      style: {
        height: `${ RecentCustomersData ? RecentCustomersData?.length === 1 ? "200px" : "auto" : RecentCustomersData?.length === 1 ? "200px" : "auto"
          }`
      }

    },
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };


  const fetchAnalytics = async () => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/stats/dashboard`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        setLoading(false);
        addToast(resJson.error.message, { appearance: "error" , autoDismiss: true,});
      }
      setLoading(false);
      const resJson = await res.json();
      addToast(resJson.error.message, { appearance: "error" , autoDismiss: true,});
    }
    setLoading(false);
    const resData = await res.json();
    setData(resData?.data);
  }

  useEffect(() => {
    fetchAnalytics()
  }, [])

  // const icons = [
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  // ]

  function formatNumberToK(n) {
    if (n < 1000) {
        return n.toString();
    } else {
        return (n / 1000).toFixed(1).replace('.0', '') + 'K';
    }
  }



  return (
    <div>
      <div className="breadcrumb">
        <AiOutlineDashboard color={"#354FAA"}/>
        <span>/</span>
        <p>Dashboard</p>
      </div>
  
      <div className="main_dashboard">
        <div className="dashboard_stats">
          <div className="dashboard_chart">
            <MonthlyDataChart data={[]}/>
          </div>
          <div className="dashboard_customers">
            <div>
              <div>
                <Table
                  title="Recent Customers"
                  customStyles={customStyles}
                  columns={columns}
                  progressPending={isLoading}
                  data={RecentCustomersData}
                  pagination={true}
                  fixedHeader={false}
                  fixedHeaderScrollHeight="1000px"
                  selectableRows={false}
                  selectableRowsHighlight={false}
                  highlightOnHover={true}
                  
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard_users">
          <div className='plans'>
            <DoughnutChart data={AllAdminStatusData?.plansCount} />
          </div>
          <div className='users'>
          {AllAdminStatusData?.userCount?.map((user) => (
            <div className="user-box">
              <div className="image-box">
                <img src={UserIcon}/>
              </div>
              
              <div className="user-box-data">
                <p className="name">{user.name}</p>
                <p className="value">{user.value}</p>
              </div>  
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard