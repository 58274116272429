import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { addressSchema, validationSchema } from '../Schema/address';
import { BsPinAngle } from 'react-icons/bs';
import { Autocomplete } from "@react-google-maps/api";
import { useToasts } from 'react-toast-notifications';

const ThirdSection = ({profile, refetch, setAddressMapToggle, userAddress, profileLoader, setProfileLoader}) => {

  const [editThirdSection, seteditThirdSection] = useState(false);
  const [personalInfo, setPersonalInfo] = useState(addressSchema)

  const token = localStorage.getItem("token");
  const { addToast } = useToasts();

  const editProfileFunc = async (data) => {
    setProfileLoader(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/users/myProfile/`,
      {
        method: "PATCH",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        setProfileLoader(false);
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      addToast(resJson.error, { appearance: "error" , autoDismiss: true,});
      seteditThirdSection(false);
      setProfileLoader(false);
      throw new Error(resJson.error.message);
    }
  
    await refetch();
    seteditThirdSection(false);
    setProfileLoader(false);
    addToast("Updated Successfully", { appearance: "success" , autoDismiss: true,});
  };


  useEffect(() => {
    userAddress.address &&
      formik.setFieldValue("address", userAddress.address);
    userAddress.city && formik.setFieldValue("city", userAddress.city);
    userAddress.state && formik.setFieldValue("state", userAddress.state);
    userAddress.country &&
      formik.setFieldValue("country", userAddress.country);
    userAddress.postCode &&
      formik.setFieldValue("postcode", userAddress.postCode);
    userAddress.latlng &&
      formik.setFieldValue("lat", userAddress.latlng?.[0]);
    userAddress.latlng &&
      formik.setFieldValue("lng", userAddress.latlng?.[1]);
  }, [userAddress]);

  const formik = useFormik({
    initialValues: personalInfo,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,

    onSubmit: (values) => {
      editProfileFunc(values);
    },
  });

  const autocompleteRef = React.useRef(null);

  const onAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const location = place.geometry.location;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: {lat: location.lat(), lng: location.lng()} }, (results, status) => {
        if (status === "OK") {
          formik.setFieldValue('address', place?.name + " " + place?.formatted_address);
          formik.setFieldValue('lat',location.lat());
          formik.setFieldValue('lng',location.lng());
          extractAddressComponents(results[0]);
        }
      });
      
    }
  };

  const extractAddressComponents = (result) => {
    const components = result.address_components;
    let city = "";
    let state = "";
    let postcode = "";
    let country = "";

    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      const types = component.types;

      if (types.includes("locality")) {
        city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (types.includes("postal_code")) {
        postcode = component.long_name;
      } else if (types.includes("country")) {
        country = component.long_name;
      }
    }

    formik.setFieldValue('city', city)
    formik.setFieldValue('state', state)
    formik.setFieldValue('postcode', postcode)
    formik.setFieldValue('country', country)
  };

  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    let script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.addEventListener("load", () => setApiLoaded(true));
    document.body.appendChild(script);
  }, []);

  if (!apiLoaded) return null;

  return apiLoaded && (
    <div className="profile__first__section tab__section" style={{ alignItems: editThirdSection ? "flex-end" : "flex-start" }}>
      <div className='profile__second__section__left' style={{ gap: editThirdSection ? "30px" : "15px", width: editThirdSection ? "100%" : "400px" }}>
        <p className='info'>Address</p>
        {
          editThirdSection ? (
            <div className='personal__second__edit'>
              <div className="personal-grid2">
                <div className="flex-input-wrap">
                  <label htmlFor="" className="grid-label2">
                    Addess:{" "}
                  </label>
                  <div className="flex">
                    <div className="flex-input">
                      <div
                        className={
                          formik.errors.address
                            ? "error-border assign_rate_with_logo input__with__error"
                            : "assign_rate_with_logo input__with__error"
                        }
                        style={{ borderRadius: "5px 0 0 5px", borderRight: "none" }}
                      >
                        <Autocomplete
                          onLoad={onAutocompleteLoad}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <input
                            type="text"
                            placeholder="Enter address"
                            value={formik.values.address}
                            onChange={(e) => formik.setFieldValue("address", e.target.value)}
                          />
                        </Autocomplete>
                        <span>{formik.errors.address}</span>
                      </div>
                      <div className="input-with-icon" 
                      onClick={() => setAddressMapToggle(true)}
                      >
                        <BsPinAngle />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="personal-grid2">
                <div className="flex-input-wrap">
                  <label htmlFor="" className="grid-label2">
                    State:{" "}
                  </label>
                  <div
                    className={
                      formik.errors.state
                        ? "error-border assign_rate_with_logo input__with__error"
                        : "assign_rate_with_logo input__with__error"
                    }
                  >
                    <input
                      type="text"
                      placeholder="Enter state"
                      value={formik.values.state}
                      onChange={(e) => formik.setFieldValue("state", e.target.value)}
                      readOnly
                    />
                    <span>{formik.errors.state}</span>
                  </div>
                </div>
                <div className="flex-input-wrap">
                  <label htmlFor="" className="grid-label2">
                    Country:{" "}
                  </label>
                  <div
                    className={
                      formik.errors.country
                        ? "error-border assign_rate_with_logo input__with__error"
                        : "assign_rate_with_logo input__with__error"
                    }
                  >
                    <input
                      type="text"
                      placeholder="Enter country"
                      value={formik.values.country}
                      onChange={(e) => formik.setFieldValue("country", e.target.value)}
                      readOnly
                    />
                    <span>{formik.errors.country}</span>
                  </div>
                </div>
              </div>

              <div className="personal-grid2">
                <div className="flex-input-wrap">
                  <label htmlFor="" className="grid-label2">
                    City:{" "}
                  </label>
                  <div
                    className={
                      formik.errors.city
                        ? "error-border assign_rate_with_logo input__with__error"
                        : "assign_rate_with_logo input__with__error"
                    }
                  >
                    <input
                      type="text"
                      placeholder="Enter city"
                      value={formik.values.city}
                      onChange={(e) => formik.setFieldValue("city", e.target.value)}
                      readOnly
                    />
                    <span>{formik.errors.city}</span>
                  </div>
                </div>
                <div className="flex-input-wrap">
                  <label htmlFor="" className="grid-label2">
                    Postcode:{" "}
                  </label>
                  <div
                    className={
                      formik.errors.postcode
                        ? "error-border assign_rate_with_logo input__with__error"
                        : "assign_rate_with_logo input__with__error"
                    }
                  >
                    <input
                      type="text"
                      placeholder="Enter postcode"
                      value={formik.values.postcode}
                      onChange={(e) => formik.setFieldValue("postcode", e.target.value)}
                      readOnly
                    />
                    <span>{formik.errors.postcode}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='personal__second__left'>
              <div className='personal__second__col_main'>
                <div className='personal__second__col'>
                  <p>Address</p>
                  <h3>{profile?.address}</h3>
                </div>
                <div className='personal__second__col'>
                  <p>Country</p>
                  <h3>{profile?.country}</h3>
                </div>
                <div className='personal__second__col'>
                  <p>Postal Code</p>
                  <h3>{profile?.postcode || "-"}</h3>
                </div>
              </div>
              <div className='personal__second__col_main'>
                <div className='personal__second__col'>
                  <p>State</p>
                  <h3>{profile?.state}</h3>
                </div>
                <div className='personal__second__col'>
                  <p>City</p>
                  <h3>{profile?.city}</h3>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className='profile__first__section__right'>
        {
          editThirdSection ? (
            <div className='profile__first__section__right'>
              <span className='edit__btn' onClick={() => formik.handleSubmit()}>Save</span>
              <span className='edit__btn' onClick={() => seteditThirdSection(!editThirdSection)}>Cancel</span>
            </div>
          ) : (
            <div 
              className='edit__btn' 
              onClick={() => {
                formik.setFieldValue('state', profile?.state);
                formik.setFieldValue('address', profile?.address)
                formik.setFieldValue('country', profile?.country)
                formik.setFieldValue('city', profile?.city)
                formik.setFieldValue('postalCode', profile?.postalCode)
                seteditThirdSection(!editThirdSection)
              }}
            >
              <span>Edit</span>
              <AiOutlineEdit size={18} />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ThirdSection