import { useState, useRef, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useResetPasswordMutation } from "../../rc-query/loginHook";
import { useNavigate } from "react-router-dom";

export const useResetPasswordInfo = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validEye, setValidEye] = useState("eye1");
  const [validation, setValidation] = useState("lgInput");
  const [inValidEmail, setInvalidEmail] = useState("noneIcon");
  const [inValidPassword, setInvalidPassword] = useState("noneIcon");
  const [validationMsg, setValidationMsg] = useState("validate-msg1");
  const { addToast } = useToasts();
  const inputRef = useRef(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const setPasswordMutation = useResetPasswordMutation(props.history);

  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otpValue, setOtpValue] = useState(['', '', '', '']);


  const handleInputChange = (e, index) => {
    const newValue = e.target.value;
    
    // Update the state with the new value
    setOtpValue((prevOtpValue) => {
      const updatedOtpValue = [...prevOtpValue];
      updatedOtpValue[index] = newValue;
      return updatedOtpValue;
    });

    // Move focus to the next input field if applicable
    if (newValue.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  // ************setStatesValues**********
  const modelFunc = (value) => {
    setModelShow(value);
  };

  const handleFoucs = () => {
    setValidEye("eye1 eyeColor");
  };

  //  ****UseEffect call**********
  useEffect(() => {
    setValidation("lgInput");
    setValidationMsg("validate-msg1");
    setValidEye("eye1");
    setInvalidEmail("noneIcon");
    setInvalidPassword("noneIcon");
  }, [email, password]);
  // ************End**********

  // ******Login Form**********
  async function SetPasswordFun(e) {
    e.preventDefault();
    const values = {
      otp: otpValue.join(""),
      password: newPassword,
      confirmPassword: confirmPassword
    };
    setLoading(true);
    // ****react-query post api call****
    setPasswordMutation.mutateAsync({ ...values }).then((data) => {

      
      if (data?.status === 200) {
        setLoading(false);

        navigate("/login");

        setValidation("lgInput");
        setValidationMsg("validate-msg1");
        addToast("Successfully Password Change, Please login now with new password", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        setLoading(false);
        setValidation("validate");
        setValidEye("eye");
        setValidationMsg("validate-msg");
        setInvalidEmail("invalid-icon1");
        setInvalidPassword("invalid-icon");
        addToast(data?.response.data.error, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
    // ************callEnd**********
  }
  // ************End**********

  // ************setFormValues**********
  const emailFunc = (e) => {
    setEmail(e.target.value);
  };
  const passwordFunc = (e) => {
    setPassword(e.target.value);
  };

  const newPasswordFunc = (e) => {
    setNewPassword(e.target.value);
  };

  const confirmPasswordFunc = (e) => {
    setConfirmPassword(e.target.value);
  };
  // ************End**********

  // Password toggle handler
  const togglePassword = () => {

    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return {
    modelShow,
    validEye,
    validation,
    inValidEmail,
    inValidPassword,
    validationMsg,
    inputRef,
    setPasswordMutation,
    passwordShown,
    confirmPasswordShown,
    emailFunc,
    passwordFunc,
    modelFunc,
    SetPasswordFun,
    togglePassword,
    toggleConfirmPassword,
    handleFoucs,
    loading,
    inputRefs,
    handleInputChange,
    newPasswordFunc,
    confirmPasswordFunc,
    otpValue,
    newPassword,
    confirmPassword
  };
};
