import axios from "axios";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const ResetPasswordApi = {
  ResetPassword: async (body) => {
    try {
      return await request.patch("api/users/resetpassword", body);
    } catch (error) {
      return error;
    }
  },
};

export default apiWrapper(ResetPasswordApi, ["ResetPassword"]);
