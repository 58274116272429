import { useState, useRef, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useLoginMutation } from "../../rc-query/loginHook";
import { useNavigate } from "react-router-dom";

export const useLoginInfo = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validEye, setValidEye] = useState("eye1");
  const [validation, setValidation] = useState("lgInput");
  const [inValidEmail, setInvalidEmail] = useState("noneIcon");
  const [inValidPassword, setInvalidPassword] = useState("noneIcon");
  const [validationMsg, setValidationMsg] = useState("validate-msg1");
  const { addToast } = useToasts();
  const inputRef = useRef(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const loginMutation = useLoginMutation(props.history);

  // ************setStatesValues**********
  const modelFunc = (value) => {
    setModelShow(value);
  };

  const handleFoucs = () => {
    setValidEye("eye1 eyeColor");
  };

  //  ****UseEffect call**********
  useEffect(() => {
    setValidation("lgInput");
    setValidationMsg("validate-msg1");
    setValidEye("eye1");
    setInvalidEmail("noneIcon");
    setInvalidPassword("noneIcon");
  }, [email, password]);
  // ************End**********

  // ******Login Form**********
  async function LoginUser(e) {
    e.preventDefault();
    const values = {
      email: email,
      password: password,
    };
    setLoading(true);
    // ****react-query post api call****
    loginMutation.mutateAsync({ ...values }).then((data) => {
      
      if (data?.data?.token) {
        setLoading(false);
        localStorage.setItem("token", data?.data?.token);
        localStorage.setItem("name", data?.data?.name);
        localStorage.setItem("role", data?.data?.userType);

        navigate("/dashboard");

        setValidation("lgInput");
        setValidationMsg("validate-msg1");
        addToast("Successfully Logged In", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        setLoading(false);
        setValidation("validate");
        setValidEye("eye");
        setValidationMsg("validate-msg");
        setInvalidEmail("invalid-icon1");
        setInvalidPassword("invalid-icon");
        addToast("Please Enter Correct Email and Passsword", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
    // ************callEnd**********
  }
  // ************End**********

  // ************setFormValues**********
  const emailFunc = (e) => {
    setEmail(e.target.value);
  };
  const passwordFunc = (e) => {
    setPassword(e.target.value);
  };
  // ************End**********

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return {
    modelShow,
    validEye,
    validation,
    inValidEmail,
    inValidPassword,
    validationMsg,
    inputRef,
    loginMutation,
    passwordShown,
    emailFunc,
    passwordFunc,
    modelFunc,
    LoginUser,
    togglePassword,
    handleFoucs,
    loading
  };
};
