import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./css/sidebar.module.css";
import dashboard from "../../images/icons/dashboard.svg";
import shipping from "../../images/icons/shipping.svg";
import shippingActive from "../../images/icons/shipping-active.svg";
import customers from "../../images/icons/customers.svg";
import customersActive from "../../images/icons/customers-active.svg";
import drivers from "../../images/icons/drivers.svg";
import driversActive from "../../images/icons/drivers-active.svg";
import contractors from "../../images/icons/contractors.svg";
import contractorsActive from "../../images/icons/contractors-active.svg";
import  {RiShoppingBasketLine, RiShutDownLine} from 'react-icons/ri';
import { BiTargetLock } from "react-icons/bi";
import {TbFileInvoice} from 'react-icons/tb';

const Sidebar = ({ onCollapse, inactive, expand, expandFunc }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const currentPath = location.pathname;
    setActiveLink(currentPath);
  }, [location.pathname]);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className={`${styles.sidebar} ${inactive ? styles.inactive : ""}`}>
      {/* <p className={styles.features}>FEATURES</p> */}
      <div className={styles.mainMenu}>
        <ul>
          <li className={styles.menuItemWrapper}>
            <Link
              to={"/dashboard"}
              className={`${styles.menuItem} ${
                activeLink === "/dashboard" ? styles.activeItem : ""
              }`}
            >
              <div className={styles.menuIcon}>
                {activeLink === "/dashboard" ? (
                  <img src={dashboard} alt="sidebar-icon" />
                ) : (
                  <img src={dashboard} alt="sidebar-icon" />
                )}
              </div>
              <p className={`${inactive ? styles.disable : styles.enable}`}>
                Dasboard
              </p>
            </Link>
          </li>
        
          <li className={styles.menuItemWrapper}>
            <Link
              to={"/customers"}
              className={`${styles.menuItem} ${
                activeLink === "/customers" ? styles.activeItem : ""
              }`}
            >
              <div className={styles.menuIcon}>
                {activeLink === "/customers" ? (
                  <img src={customersActive} alt="sidebar-icon" />
                ) : (
                  <img src={customers} alt="sidebar-icon" />
                )}
              </div>
              <p className={`${inactive ? styles.disable : styles.enable}`}>
                Customers
              </p>
            </Link>
          </li>

          <li className={styles.menuItemWrapper}>
            <Link
              to={"/plans"}
              className={`${styles.menuItem} ${
                activeLink === "/plans" ? styles.activeItem : ""
              }`}
            >
              <div className={styles.menuIcon}>
                {activeLink === "/plans" ? (
                  <img src={shippingActive} alt="sidebar-icon" />
                ) : (
                  <img src={shipping} alt="sidebar-icon" />
                )}
              </div>
              <p className={`${inactive ? styles.disable : styles.enable}`}>
                Plans
              </p>
            </Link>
          </li>
          
          
          <li className={styles.menuItemWrapper} onClick={logout}>
            <p className={`${styles.menuItem} ${styles.logout}`} style={{color: "red"}}>
              <div className={styles.menuIconLogout} >
                <RiShutDownLine/>
              </div>
              <p className={`${inactive ? styles.disable : styles.enable}`} style={{color: "red"}}>
                LogOut
              </p>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
