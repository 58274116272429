import React from "react";
import { VscChromeClose } from "react-icons/vsc";
import modalImage1 from "../../../images/modals/packagedetail.png";
import TimeKeeper from "react-timekeeper";
import pin from "../../../images/icons/pin.svg";
import { useState } from "react";
import {AiOutlineLoading} from 'react-icons/ai';
import { Autocomplete } from "@react-google-maps/api";


const EditPickupDetailModal = ({
    editModal,
    formik,
    setShowTime,
    values,
    handleChange,
    errors,
    setAddressMapToggle,
    handleSubmit,
    setEditModal,
    setAddressTable,
    name,
    showTime,
    setStartTime
}) => {

    const [isLoading, setloading] = useState(false)
    const autocompleteRef = React.useRef(null);
    
    const onAutocompleteLoad = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    };

    const onPlaceChanged = () => {
        if (autocompleteRef.current) {
          const place = autocompleteRef.current.getPlace();
          const location = place.geometry.location;
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: {lat: location.lat(), lng: location.lng()} }, (results, status) => {
            if (status === "OK") {
              formik.setFieldValue('address', place?.name + " " + place?.formatted_address);
              formik.setFieldValue('lat',location.lat());
              formik.setFieldValue('lng',location.lng());
              extractAddressComponents(results[0]);
            }
          });
          
        }
    };
    
    const extractAddressComponents = (result) => {
    const components = result.address_components;
    let city = "";
    let state = "";
    let postcode = "";
    let country = "";

    for (let i = 0; i < components.length; i++) {
        const component = components[i];
        const types = component.types;

        if (types.includes("locality")) {
        city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
        } else if (types.includes("postal_code")) {
        postcode = component.long_name;
        } else if (types.includes("country")) {
        country = component.long_name;
        }
    }

    formik.setFieldValue('city', city)
    formik.setFieldValue('state', state)
    formik.setFieldValue('postcode', postcode)
    formik.setFieldValue('country', country)
    };

    return (
        <>
            {editModal &&
                <div className="modalMask">
                    <div className="userModalWrapper">
                        <div className="modal__body">
                            <div>
                                <div className="usermodal__body modal__body">
                                    <div className="flex justify-flex">
                                        <img src={modalImage1} alt="" />
                                        <button
                                            className="close-button"
                                            onClick={() => {
                                                setEditModal(false);
                                                setAddressTable({ address: "" });
                                                formik.resetForm();
                                            }}
                                        >
                                            <VscChromeClose />
                                        </button>
                                    </div>
                                    {showTime && (
                                        <div className="modalMask">
                                            <div className="modalWrapper">
                                                <TimeKeeper
                                                    // time={time}
                                                    onChange={(newTime) => {
                                                        formik.setFieldValue("time", newTime.formatted12);
                                                        setStartTime(newTime.formatted12);
                                                    }}
                                                    onDoneClick={() => setShowTime(false)}
                                                    switchToMinuteOnHourSelect
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <form onSubmit={(e) => {
                                        e.preventDefault()
                                        setloading(true)
                                        setTimeout(() => {
                                            setloading(false)
                                            handleSubmit()
                                        }, 1000)
                                    }} className="usermodal__body" action="">
                                        <div className="package-edit-details">{`Edit ${name} Details`}</div>
                                        <div className="flex justify-between">
                                            <div className="please-enter-the-req">
                                                Please enter required details correctly thanks
                                            </div>
                                            <div className="please-enter-the-req">
                                                <span>{`${name}-ID:`}</span>
                                                <span style={{ paddingLeft: "10px" }}>{values?._id}</span>
                                            </div>

                                        </div>

                                        <div className="grid-flex">
                                            <div>
                                                <label htmlFor="" className="grid-label">
                                                    Name
                                                </label>
                                                <div className="input__with__error">
                                                    <div className="assign_rate_with_logo">
                                                        <input
                                                            type="text"
                                                            value={values?.name}
                                                            onChange={handleChange}
                                                            name="name"
                                                            placeholder="Name"
                                                        />
                                                    </div>
                                                    <span>{errors.name}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="" className="grid-label">
                                                    Email
                                                </label>
                                                <div className="input__with__error">
                                                    <div className="assign_rate_with_logo">
                                                        <input
                                                            type="text"
                                                            value={values?.email}
                                                            onChange={handleChange}
                                                            name="email"
                                                            placeholder="Email"

                                                        />
                                                    </div>
                                                    <span>{errors?.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-flex">
                                            <div >
                                                <div >
                                                    <label htmlFor="" className="grid-label">
                                                        Address
                                                    </label>
                                                    <div className="input__with__error">
                                                        <div className="assign_rate_with_logo">
                                                            <div style={{width: "100%"}}>
                                                                <Autocomplete
                                                                    onLoad={onAutocompleteLoad}
                                                                    onPlaceChanged={onPlaceChanged}
                                                                    style={{width: "100%"}}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter address"
                                                                        style={{width: "100%"}}
                                                                        value={formik.values.address}
                                                                        onChange={(e) => formik.setFieldValue("address", e.target.value)}
                                                                    />
                                                                </Autocomplete>
                                                            </div>
                                                            <div >
                                                                <img
                                                                    src={pin}
                                                                    alt=""
                                                                    onClick={() => setAddressMapToggle(true)}
                                                                    style={{ cursor: "pointer", paddingBottom: "5px" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <span>{errors.address}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid-flex">
                                                <div>
                                                    <label htmlFor="" className="grid-label">
                                                        City
                                                    </label>
                                                    <div className="input__with__error">
                                                        <div className="assign_rate_with_logo">
                                                            <input
                                                                type="text"
                                                                value={values?.city}
                                                                onChange={handleChange}
                                                                name="city"
                                                                readOnly
                                                                placeholder="Enter City"
                                                            />
                                                        </div>
                                                        <span>{errors.city}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="" className="grid-label">
                                                        State
                                                    </label>
                                                    <div className="input__with__error">
                                                        <div className="assign_rate_with_logo">
                                                            <input
                                                                type="text"
                                                                value={values?.state}
                                                                onChange={handleChange}
                                                                name="state"
                                                                readOnly
                                                                placeholder="Enter State"

                                                            />
                                                        </div>
                                                        <span>{errors?.state}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-flex">
                                            <div className="flex" >
                                                <div >
                                                    <label htmlFor="" className="grid-label">
                                                        Postcode
                                                    </label>
                                                    <div className="input__with__error">
                                                        <div className="assign_rate_with_logo">
                                                            <input
                                                                type="text"
                                                                value={values?.postcode}
                                                                onChange={handleChange}
                                                                name="postcode"
                                                                readOnly
                                                                placeholder="Enter Postcode"
                                                            />
                                                        </div>
                                                        <span>{errors.postcode}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="" className="grid-label">
                                                        Country
                                                    </label>
                                                    <div className="input__with__error">
                                                        <div className="assign_rate_with_logo">
                                                            <input
                                                                type="text"
                                                                value={values?.country}
                                                                onChange={handleChange}
                                                                name="country"
                                                                readOnly
                                                                placeholder="Enter Country"
                                                            />
                                                        </div>
                                                        <span>{errors.country}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="grid-flex">
                                                <div>
                                                    <label htmlFor="" className="grid-label">
                                                        Date
                                                    </label>
                                                    <div className="input__with__error">
                                                        <div className="assign_rate_with_logo">
                                                            <input
                                                                type="date"
                                                                min={new Date().toISOString().split("T")[0]}
                                                                placeholder="Select Date"
                                                                value={formik.values.date}
                                                                onChange={(e) =>
                                                                    formik.setFieldValue("date", e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                        <span>{errors.date}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="" className="grid-label">
                                                        Time
                                                    </label>
                                                    <div className="input__with__error">
                                                        <div className="assign_rate_with_logo">
                                                            <input
                                                                type="text"
                                                                value={values.time}
                                                                placeholder="Set Time"
                                                                onClick={() => setShowTime(true)}

                                                            />
                                                        </div>
                                                        <span>{errors?.time}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-flex">
                                            <div>
                                                <label htmlFor="" className="grid-label">
                                                    Company Name
                                                </label>
                                                <div className="input__with__error">
                                                    <div className="assign_rate_with_logo">
                                                        <input
                                                            type="text"
                                                            value={values?.company}
                                                            placeholder="Enter Company"
                                                            onChange={handleChange}
                                                            name="company"

                                                        />
                                                    </div>
                                                    <span>{errors?.company}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="" className="grid-label">
                                                    Special Instructions
                                                </label>
                                                <div className="input__with__error">
                                                    <div className="assign_rate_with_logo">
                                                        <input
                                                            type="text"
                                                            value={values?.specialInstructions}
                                                            placeholder="Enter Special Instructions"
                                                            onChange={handleChange}
                                                            name="specialInstructions"

                                                        />
                                                    </div>
                                                    <span>{errors?.specialInstructions}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-center p-t10">
                                            <button type="submit" className="flex-button" >
                                                {isLoading ? <AiOutlineLoading className="loaderIcon" size={18} /> : "Save"}
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default EditPickupDetailModal;
