import React from "react";
import Select from "react-select";

const Dropdown = ({
  options,
  value,
  defaultValue,
  onChange,
  isClearable,
  components,
  error,
  width,
  ...rest
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: width ? width : "100%",
      height: "30px",
      fontSize: "14px",
      border: `${error ? "1.5px solid red" : "1.5px solid #BEBEBE"}`,
      borderLeft: rest.joinedSelectLeft ? "none" : "1.5px solid #BEBEBE",
      borderRight: value !== "own_driver" ? "1.5px solid #BEBEBE" : rest.joinedSelectRight ? "none" : "1.5px solid #BEBEBE",
      borderRadius: rest.joinedSelectLeft ? "0 5px 5px 0" 
      : rest.joinedSelectRight ? "5px 0px 0px 5px" : "5px",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "150px",
      marginTop: "0",
    }),
  };

  return (
    <div style={{width: "100%"}}>
      <Select
        options={options}
        value={value || defaultValue}
        isClearable={isClearable ? true : false}
        onChange={onChange}
        components={components}
        styles={customStyles}
        isDisabled={rest.isDisabled}
      />
    </div>
  );
};

export default Dropdown;
