import React, { useState } from 'react'
import searchIcon from '../../../images/icons/searchbaricon.svg';
import close from '../../../images/icons/closeSeaarchBar.svg';
import image from '../../../images/icons/user.png';
import '../css/searchCustomerModal.css'
import { useToasts } from "react-toast-notifications";
import {BsFilePerson} from "react-icons/bs";

const SearchCustomerModal = ({
    searchCustomer,
    setSearchCustomer,
    formik,
    handleCustomerSelect,
    placeholder,
    url,
    target,
    searchFor
}) => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false);
    const [keyword, setKeyword] = useState("")
    const token = localStorage.getItem("token");
    const { addToast } = useToasts();

    let debounceTimeout;
    const debounce = (func, key, delay) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            func(key);
        }, delay);
    };

    const handleInputChange = (e) => {
        setKeyword(e.target.value);
        const search = () => searchHandler(e.target.value);
        debounce(search, 300);
    };

    const searchHandler = async (keyword) => {
        setLoader(true);
        let formData = target === "name"
            ? { name: keyword }
            : { email: keyword };
        let res;
        if (searchFor !== "shipment") {
            res = await fetch(
                `${process.env.REACT_APP_BASE_URL}${url}`,
                {
                    method: "POST",
                    redirect: "follow",
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formData),
                }
            );
        } else {
            res = await fetch(
                `${process.env.REACT_APP_BASE_URL}${url}/${keyword}`,
                {
                    method: "GET",
                    redirect: "follow",
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json"
                    }
                }
            );
        }
        if(res?.status !== 200) {
            setLoader(false);
        }
        if (!res.ok) {
            setLoader(false);
            if (res.status === 401) {
                const resJson = await res.json();
                throw new Error(resJson.error.message);
            }
            const resJson = await res.json();
            addToast(resJson.error, {
                appearance: "error",
                autoDismiss: true,
            });
            throw new Error(resJson.error.message);
        }

        const responseData = await res.json();
        setLoader(false);
        if (searchFor === "pickup") {
            setData(responseData.pickup);
        } else if (searchFor === "delivery") {
            setData(responseData.delivery)
        } else {
            setData(responseData.user)
        }
    }

    const handleSubmit = (customer) => {
        if (customer) {
            handleCustomerSelect(customer);
            setSearchCustomer(false);
            setKeyword("");
            setData([]);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            searchHandler();
        }
    };

    return (
        <>
            {
                searchCustomer && (
                    <div className="modalMask">
                        <div
                            style={{ width: "512px", height: "386px", borderRadius: "13px" }}
                            className="userModalWrapper"
                        >
                            <div className="search__body">
                                <form className="searchbar" onKeyDown={handleKeyDown}>
                                    <img src={searchIcon} alt="search-icon" onClick={searchHandler} />
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        value={keyword}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <img src={close} alt="close-icon" onClick={() => {
                                        setSearchCustomer(false);
                                        setKeyword("");
                                        setData([]);
                                    }} />
                                </form>
                                <div className="search__result">
                                    <p>Search Results</p>
                                    <div className="results">
                                        {
                                            loader ? (
                                                <div className='track__main__loader'>
                                                    <div class="lds-dual-ring"></div>
                                                </div>
                                            ) : 
                                                data.length > 0 ? data.map((customer, index) => {
                                                    return (
                                                        <div className='customer_card' onClick={() => handleSubmit(customer)} key={index}>
                                                            <div className='avatar__wrap'>
                                                                {
                                                                    customer.profilePhoto ? (
                                                                        <img src={customer?.profilePhoto || image} alt="customer-avatar" />
                                                                    ) : (
                                                                        <BsFilePerson size={22}/>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='customer__info'>
                                                                {
                                                                    searchFor === "shipment" ? (
                                                                        <h4>{customer.firstName} {customer.lastName}</h4>
                                                                    ) : (
                                                                        <h4>{customer.name}</h4>
                                                                    )
                                                                }
                                                                <span>{customer.email}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : (
                                                    <div className='no__data'>No data found</div>
                                                )
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SearchCustomerModal