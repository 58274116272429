// ****** contractors get Api******

export const getRidersFunc = async (token) => {
  // return await (
  const res = await fetch(`${process.env.REACT_APP_BASE_URL}api/users/riders`, {
    method: "GET",
    redirect: "follow",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
};

// ****** contractors create Api******
export const createRiderFunc = async (token, data, addToast) => {
  delete data._id;
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/rider-register`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, { appearance: "error", autoDismiss: true });
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};

export const createRiderWithoutToken = async ( data, addToast) => {
  delete data._id;
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/rider-register-user`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, { appearance: "error", autoDismiss: true });
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};

// ****** contractors edit Api******
export const editRiderFunc = async (token, data, addToast) => {
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/profile/${data?._id}`,
    {
      method: "PATCH",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, { appearance: "error", autoDismiss: true });
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};

// ****** drivers get Api******
export const getdriversFunc = async (token, status, suspend) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/users/riders?status=${status}&suspened=${suspend}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      //   history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};

// ****** user get Api******
export const getUserFunc = async (token, id) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/orders/order/${id}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      //   history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};
