import React, { useEffect, useState } from 'react'
import FirstSection from './Sections/FirstSection';
import SecondSection from './Sections/SecondSection';
import ThirdSection from './Sections/ThirdSection';
import { AiOutlineLoading } from 'react-icons/ai';

const ProfileTab = ({setAddressMapToggle, userAddress}) => {

  const [profileData, setProfileData] = useState(null);
  const [profileLoader, setProfileLoader] = useState(false);

  const token = localStorage.getItem("token");

  const getProfile = async () => {
    setProfileLoader(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/users/profile`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        setProfileLoader(false);
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      setProfileLoader(false);
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
    setProfileData(data);
    setProfileLoader(false);
  };
  
  useEffect(() => {
    getProfile();
  }, [])

  return (
    <div className='profile__tab__wrapper'>
      {(profileLoader) && (
          <div className='loader__overlay'>
            <AiOutlineLoading style={{ margin: "auto", textAlign: "center" }} className="loaderIcon" size={50} />
          </div>
      )}
      <FirstSection 
        profile={profileData} 
        refetch={() => getProfile()} 
        profileLoader={profileLoader}
        setProfileLoader={setProfileLoader}
      />
      <SecondSection 
        profile={profileData} 
        refetch={() => getProfile()}
        profileLoader={profileLoader}
        setProfileLoader={setProfileLoader} 
      />
      <ThirdSection 
        profile={profileData} 
        refetch={() => getProfile()} 
        setAddressMapToggle={setAddressMapToggle}
        userAddress={userAddress}
        profileLoader={profileLoader}
        setProfileLoader={setProfileLoader}
      />
    </div>
  )
}

export default ProfileTab